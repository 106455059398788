import {Component, Input} from '@angular/core';

@Component({
  selector: 'ap-percent-bar',
  templateUrl: 'ap-percent-bar.component.html',
})
export class ApPercentBarComponent {
  @Input() value: number;
  @Input() valueText: string;
  @Input() maximum = 100;
  @Input() showValue = true;
  @Input() containerStyles = this._getContainerStyles();
  @Input() barStyles = this._getBarStyles();
  @Input() enableTooltip = false;
  @Input() tooltipValue = '';

  private _getContainerStyles(): any {
    return {
      backgroundColor: 'grey',
      borderRight: 'solid 1px'
    };
  }

  private _getBarStyles(): any {
    return {
      backgroundColor: '#b0cb1f',
      color: '#000000'
    };
  }
}
