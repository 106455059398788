import {Injectable} from '@angular/core';
import {
  OperationModesEnum
}                   from '../../stores/common/operation-modes.store';
import {
  FertilizerStore
}                   from '../../stores/base-data/fertilizer.store';
import {
  ApTranslationService
}                   from '../../ap-utils/service/ap-translation.service';
import {
  TranslationStore
}                   from '../../stores/translation/translation.store';
import {
  FieldStore
}                   from '../../stores/farm/field.store';
import {
  GetRoundNumericService
}                   from '../../ap-utils/service/get-round-numeric.service';
import {
  CropTypeStore
}                   from '../../stores/base-data/crop.types.store';
import {
  ApOperationMode
}                   from '../../ap-interface/enums/ap-operation-mode.enum';
import {
  ApDateService
}                   from '../../ap-core/services/ap-date-service';
import {
  TaskManagementConstants
}                   from '../../task-management/task-management.constants';
import {
  NFertilizationService
}                   from '../../services/data/n-fertilization.service';
import {
  FertilizerService
}                   from '../../services/data/fertilizer.service';
import {
  FertilizationManagementService
}                   from '../../services/data/fertilization-management.service';
import {
  GetOperationModeService
}                   from '../../ap-utils/service/ap-get-operationmode.service';
import {
  ContentNRelatedPlanBookSourceType,
  IContentNRelatedPlanBook,
  IContentNRelatedPlanBookStatisticValue
}                   from '../../types/n-fertilization.types';
import {
  CropApplicationStore
}                   from '../../stores/task-management/crop-application.store';
import {
  IGridDetailsItemContentRange,
  INFertilizationOverviewGridDetailsItem
}                   from '../types/n-fertilization-overview.types';
import {
  Create
}                   from 'ts-tooling';
import INFertilizationPlanBook = Data.TaskManagement.INFertilizationPlanBook;
import IOperationMode = Data.Common.IOperationMode;
import IDatasetFieldMap = Data.Sensor.IDatasetFieldMap;
import IField = Data.FieldManagement.IField;
import IBasicFertilisationPlanBooks = Data.TaskManagement.IBasicFertilisationPlanBooks;

@Injectable({providedIn: 'root'})
export class ApNFertilizationCommonService {
  constructor(private fertilizerStore: FertilizerStore,
              private translationService: ApTranslationService,
              private translationStore: TranslationStore,
              private fieldStore: FieldStore,
              private cropTypeStore: CropTypeStore,
              private cropApplicationStore: CropApplicationStore,
              private fertilizerService: FertilizerService,
              private dateService: ApDateService,
              private operationModeService: GetOperationModeService,
              private nFertilizationService: NFertilizationService,
              private fertilizationManagementService: FertilizationManagementService,
              private roundNumericService: GetRoundNumericService) {
  }

  public getOperationMode(mode: IOperationMode): string {
    return this.translationStore.FindTranslationForSelectedLanguage(mode?.Key);
  }

  /*
   * Berechnet die Summe der Ausbringmenge über alle Einzelplanungen
   */
  public getApplSum(planBooks: INFertilizationPlanBook[]): number {
    if (!planBooks || planBooks.length === 0) {
      return undefined;
    }
    const planBook = planBooks[0];
    // APV49-1517: Bei den N-Düngung varianten N-Dünugn und Abs. Raps. bitte keine Summe berechnen.
    // Dies ist in der N-Düngung nur bei Zielwert und Streukarte möglich.
    if (planBook.OperationMode?.Key !== OperationModesEnum.TargetValue &&
      planBook.OperationMode?.Key !== OperationModesEnum.ApplicationMap && planBook.OperationMode?.Key !== OperationModesEnum.Constant) {
      return undefined;
    }
    const productAmount = this.nFertilizationService.calculateProductAmountFromPlanBooks(planBooks);
    if (productAmount === null) {
      return undefined;
    }
    return productAmount;
  }

  /*
   * Ermittelt die Einheit des verwendeten Produkts/Dünger
   */
  public getProductUnit(planBook: INFertilizationPlanBook): string {
    if (!planBook) {
      return '';
    }
    // APV49-1517: Bei den N-Düngung varianten N-Dünugn und Abs. Raps. bitte keine Summe berechnen.
    // Dies ist in der N-Düngung nur bei Zielwert und Streukarte möglich.
    if (planBook.OperationMode?.Key !== OperationModesEnum.TargetValue &&
      planBook.OperationMode?.Key !== OperationModesEnum.ApplicationMap && planBook.OperationMode?.Key !== OperationModesEnum.Constant) {
      return '';
    }
    const product = this.fertilizerStore.getFertilizer(planBook.ProductId);
    let unit = 'Global__Unit_kg'; // fallback
    if (product && product.Unit && product.Unit.BaseUnit) {
      unit = product.Unit.BaseUnit.Key;
    } else if (product && product.Unit) {
      unit = product.Unit.Key;
    }
    return this.translationService.translate(unit);
  }

  /**
   * Retrieves and processes N fertilization applications for a field
   * @param field - Target field
   * @param nFertPlanBooks - N fertilization plan books
   * @param logfiles - Dataset field mappings for N fertilization
   * @param baseFertPlanBooks - Basic fertilization plan books
   * @returns Processed N fertilization applications
   * @public
   */
  public getContentNPlanBooks(field: IField,
                              nFertPlanBooks: INFertilizationPlanBook[],
                              logfiles: IDatasetFieldMap[],
                              baseFertPlanBooks: IBasicFertilisationPlanBooks[]): INFertilizationOverviewGridDetailsItem[] {
    const contentNPlanBooks = this.nFertilizationService.getContentNRelatedPlanBooks(field, nFertPlanBooks, logfiles, baseFertPlanBooks);
    if (!contentNPlanBooks || contentNPlanBooks.length <= 0) {
      return [];
    }
    return contentNPlanBooks.map(contentNPlanBook => {
      const fieldGeom = this.fieldStore.getFieldGeomById(contentNPlanBook.FieldGeomId);
      const cropType = this.cropTypeStore.getDefaultCroptype(contentNPlanBook.CropTypeId);
      const operationMode = this.operationModeService.getOperationModeById(contentNPlanBook.OperationModeId);
      const fertilizer = this.fertilizerStore.getFertilizer(contentNPlanBook.ProductId);
      const status = this.getStatusForApplication(contentNPlanBook?.NFertilization, contentNPlanBook?.NUptake,
        contentNPlanBook?.EcValue ?? 0, cropType?.Eppocode, operationMode);
      const area = fieldGeom?.AreaHa ?? 0;
      const productTotal = contentNPlanBook.NFertilization
        ? this.fertilizationManagementService.calculateProductAmountN(contentNPlanBook.ProductId, contentNPlanBook.NFertilization.Average, area)
        : null;
      const productTotalUnit = this.fertilizerService.getFertilizerUnit(fertilizer, false);
      const item: INFertilizationOverviewGridDetailsItem = {
        Id: contentNPlanBook.PlanBookId,
        PlanBookId: contentNPlanBook.PlanBookId,
        FieldId: field.Id,
        Status: status,
        StatusClass: this.getStatusClassForApplication(status),
        StatusDesc: this.getStatusDescriptionForApplication(contentNPlanBook.SourceType, status, operationMode),
        ProductName: this._getProductName(operationMode, contentNPlanBook.ProductId),
        ProductId: contentNPlanBook.ProductId,
        CropKey: cropType?.CropKey,
        WorkArea: this.roundNumericService.roundAsNumber(area, 4),
        Administration: this._getAdministration(contentNPlanBook, cropType?.Eppocode, operationMode),
        Application: this.cropApplicationStore.GetApplication(cropType?.Eppocode, contentNPlanBook.EcValue),
        EC: contentNPlanBook.EcValue,
        Date: contentNPlanBook.ApplicationDate,
        MidnightDate: this.dateService.getDateMidnight(contentNPlanBook.ApplicationDate),
        ProductTotal: productTotal,
        ProductTotalUnit: Number.isFinite(productTotal) && productTotalUnit ? productTotalUnit : '-',
        AlreadyApplied: this.nFertilizationService.getAlreadyApplied(contentNPlanBook?.NFertilization?.Average, contentNPlanBook.ProductId),
        FertilizerEfficiency: fertilizer?.Efficiency,
        Absorption: this._getContentRangeValue(contentNPlanBook?.NUptake),
        Fertilization: this._getContentRangeValue(contentNPlanBook?.NFertilization)
      };
      return item;
    });
  }

  public getStatusForApplication(nApplication: IContentNRelatedPlanBookStatisticValue | undefined,
                                 nUptake: IContentNRelatedPlanBookStatisticValue | undefined,
                                 ecValue: number, eppoCode: string, operationMode: IOperationMode | null): number {
    if (!nApplication && !nUptake) {
      return -1;
    }
    const isApplicationRateNScan = this.cropApplicationStore.GetApplication(eppoCode, ecValue, operationMode) === -1;
    if (isApplicationRateNScan) {
      return 2;
    }
    if (operationMode && operationMode.Id === ApOperationMode.Const) {
      return 0;
    }
    return 1;
  }

  public getStatusClassForApplication(status: number): string {
    switch (status) {
      case -1:
        return 'ap-status-red';
      case 0:
        return 'ap-status-yellow';
      case 1:
        return 'ap-status-green';
      case 2:
        return 'ap-status-grey';
      default:
        return '';
    }
  }

  public getStatusDescriptionForApplication(sourceType: ContentNRelatedPlanBookSourceType | undefined,
                                            status: number, operationMode?: IOperationMode): string {
    if (status === -1) {
      return this.translationService.translate('Global__Please_Contact_The_Support');
    }
    let statusDescription = this.translationService.translate(operationMode.Key);
    if (sourceType && sourceType === ContentNRelatedPlanBookSourceType.BaseFertPlanBook) {
      statusDescription = `${this.translationStore.FindTranslationForSelectedLanguage('Global__BasicFertilization')} / ${statusDescription}`;
    }
    return statusDescription;
  }

  public calculateAreaWeightedAverage(items: { Area: number | undefined, Average: number | undefined }[]): number {
    const filteredItems = items.filter(x => !!x.Average);
    const totalArea = filteredItems.reduce((areaSum, field) => areaSum + Create(field?.Area, 0), 0);
    const averageWithFieldsArea = filteredItems.reduce((sensorAvgSum, item) => {
      const sensorAvg = item.Average > -1 ? item.Average * Create(item?.Area, 0) : 0;
      return sensorAvgSum + sensorAvg;
    }, 0);
    if (!averageWithFieldsArea || !totalArea) {
      return 0;
    }
    return averageWithFieldsArea / totalArea;
  }

  private _getProductName(operationMode: IOperationMode, productId: number | null): string {
    if (!operationMode || operationMode.Key === TaskManagementConstants.OPERATION_MODE_BONITUR) {
      return '-';
    }
    if (productId === null || productId === 0) {
      return this.translationStore.FindTranslationForSelectedLanguage('Nutrient__PureNutrient');
    }
    const product = this.fertilizerStore.getFertilizer(productId);
    if (!product) {
      return '-';
    }
    return product.Prodname;
  }

  private _getAdministration(contentNPlanBook: IContentNRelatedPlanBook, eppoCode: string, operationMode: IOperationMode | null): string {
    if (contentNPlanBook.SourceType === ContentNRelatedPlanBookSourceType.BaseFertPlanBook) {
      return 'Global__N0';
    }
    return this.nFertilizationService.getApplicationRateName(eppoCode, contentNPlanBook.EcValue, operationMode, true);
  }

  private _getContentRangeValue(values: IContentNRelatedPlanBookStatisticValue | null): IGridDetailsItemContentRange {
    if (!values) {
      return null;
    }
    return {
      Min: values.Min,
      Max: values.Max,
      Avg: values.Average
    };
  }
}
