import IFieldNutrientsMap = Data.Nutrients.IFieldNutrientsMap;
import ISoilSampleFieldDistribution = Data.Nutrients.ISoilSampleFieldDistribution;
import ISoilSample = Data.Nutrients.ISoilSample;
import {IAction, IApInvokerArguments} from '../../interfaces';

export enum SoilSampleFieldActions {
  SOIL_SAMPLE_FIELD_LOAD = '[soilsamplefield] load',
  SOIL_SAMPLE_FIELD_LOAD_SUCCESS = '[soilsamplefield] load success',
  SOIL_SAMPLE_FIELD_LOAD_FAIL = '[soilsamplefield] load fail',
  SOIL_SAMPLE_FIELD_DISTRIBUTIONS_LOAD = '[soilsamplefield] distributions load',
  SOIL_SAMPLE_FIELD_DISTRIBUTIONS_LOAD_SUCCESS = '[soilsamplefield] distributions load success',
  SOIL_SAMPLE_FIELD_DISTRIBUTIONS_DRAW_COMPLETE = '[soilsamplefield] distributions draw complete',
  SOIL_SAMPLE_LOAD = '[soilsample] load',
  SOIL_SAMPLE_LOAD_SUCCESS = '[soilsample] load success',
  SOIL_SAMPLE_DRAW_COMPLETE = '[soilsample] draw complete',
  SAMPLE_TRACKS_SHAPE_EXPORT = '[sample] tracks shape export',
  SAMPLE_TRACKS_SHAPE_EXPORT_SUCCESS = '[sample] tracks shape export success',
  SAMPLE_POINTS_EXCEL_EXPORT = '[sample] points excel export',
  SAMPLE_POINTS_EXCEL_EXPORT_SUCCESS = '[sample] points excel export success',
  SAMPLE_TRACKS_INFO = '[sample] tracks info',
  SAMPLE_TRACKS_INFO_SUCCESS = '[sample] tracks info success'
}

export class SoilSampleFieldLoad implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_FIELD_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSampleFieldLoadSuccess implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_FIELD_LOAD_SUCCESS;

  constructor(public payload: IFieldNutrientsMap[]) {
  }
}

export class SoilSampleFieldLoadFail implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_FIELD_LOAD_FAIL;

  constructor(public payload: string) {
  }
}

export class SoilSampleFieldDistributionLoad implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_FIELD_DISTRIBUTIONS_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSampleFieldDistributionLoadSuccess implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_FIELD_DISTRIBUTIONS_LOAD_SUCCESS;

  constructor(public payload: ISoilSampleFieldDistribution[]) {
  }
}

export class SoilSampleFieldDistributionDrawComplete implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_FIELD_DISTRIBUTIONS_DRAW_COMPLETE;
}

export class SoilSampleLoad implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SoilSampleLoadSuccess implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_LOAD_SUCCESS;

  constructor(public payload: ISoilSample[]) {
  }
}

export class SoilSampleDrawComplete implements IAction {
  readonly type = SoilSampleFieldActions.SOIL_SAMPLE_DRAW_COMPLETE;
}

export class SampleTracksShapeExport implements IAction {
  readonly type = SoilSampleFieldActions.SAMPLE_TRACKS_SHAPE_EXPORT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SampleTracksShapeExportSuccess implements IAction {
  readonly type = SoilSampleFieldActions.SAMPLE_TRACKS_SHAPE_EXPORT_SUCCESS;

  constructor(public payload: ArrayBuffer) {
  }
}

export class SamplePointsExcelExport implements IAction {
  readonly type = SoilSampleFieldActions.SAMPLE_POINTS_EXCEL_EXPORT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SamplePointsExcelExportSuccess implements IAction {
  readonly type = SoilSampleFieldActions.SAMPLE_POINTS_EXCEL_EXPORT_SUCCESS;

  constructor(public payload: ArrayBuffer) {
  }
}

export class SampleTracksInfo implements IAction {
  readonly type = SoilSampleFieldActions.SAMPLE_TRACKS_INFO;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class SampleTracksInfoSuccess implements IAction {
  readonly type = SoilSampleFieldActions.SAMPLE_TRACKS_INFO_SUCCESS;

  constructor(public payload: ArrayBuffer) {
  }
}
