import {Component} from '@angular/core';

const CONTENT = `<svg viewBox="0 0 580 580" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" class="svg-inline--fa fa-info fa-w-6" data-icon="info" data-prefix="fas">
  <circle cx="290.5" cy="291" fill="#bb8f00" id="svg_2" r="278" stroke="#000000" transform="matrix(1 0 0 1 0 0)"/>
  <path d="m216,451.23l20,0l0,-144.46l-20,0c-11.05,0 -20,-8.95 -20,-20l0,-47.77c0,-11.05 8.95,-20 20,-20l112,0c11.05,0 20,8.95 20,20l0,212.23l20,0c11.05,0 20,8.95 20,20l0,47.77c0,11.05 -8.95,20 -20,20l-152,0c-11.05,0 -20,-8.95 -20,-20l0,-47.77c0,-11.05 8.95,-20 20,-20zm76,-424.23c-39.77,0 -72,32.23 -72,72s32.23,72 72,72s72,-32.23 72,-72s-32.24,-72 -72,-72z" fill="#FFFFFF" id="svg_1"/>
</svg>`;

@Component({
  selector: 'ap-info-icon-round',
  template: CONTENT
})
export class ApInfoIconRoundComponent {
}
