<ng-container *ngIf="id === 'ap-dashboard-icon'" xmlns="" xmlns="">
  <ap-dashboard-icon [class]="cssClass"></ap-dashboard-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-basedata-icon'">
  <ap-basedata-icon [class]="cssClass"></ap-basedata-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-upload-icon'">
  <ap-upload-icon [class]="cssClass"></ap-upload-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-fungidetect-icon'">
  <ap-fungidetect-icon [class]="cssClass"></ap-fungidetect-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-database-icon'">
  <ap-database-icon [class]="cssClass"></ap-database-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-file-icon'">
  <ap-file-icon [class]="cssClass"></ap-file-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-map-icon'">
  <ap-map-icon [class]="cssClass"></ap-map-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-documentation-icon'">
  <ap-documentation-icon [class]="cssClass"></ap-documentation-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-contact-icon'">
  <ap-contact-icon [class]="cssClass"></ap-contact-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-legal-icon'">
  <ap-legal-icon [class]="cssClass"></ap-legal-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-imprint-icon'">
  <ap-imprint-icon [class]="cssClass"></ap-imprint-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-agreement-icon'">
  <ap-agreement-icon [class]="cssClass"></ap-agreement-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-exclamation-circle-icon'">
  <ap-exclamation-circle-regular-icon [class]="cssClass"></ap-exclamation-circle-regular-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-exclamation-circle-solid-icon'">
  <ap-exclamation-circle-solid-icon [class]="cssClass"></ap-exclamation-circle-solid-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-check-circle-icon'">
  <ap-check-circle-regular-icon [class]="cssClass"></ap-check-circle-regular-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-check-circle-solid-icon'">
  <ap-check-circle-solid-icon [class]="cssClass"></ap-check-circle-solid-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-times-circle-icon'">
  <ap-times-circle-regular-icon [class]="cssClass"></ap-times-circle-regular-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-times-circle-solid-icon'">
  <ap-times-circle-solid-icon [class]="cssClass"></ap-times-circle-solid-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-ban-solid-icon'">
  <ap-ban-solid-icon [class]="cssClass"></ap-ban-solid-icon>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-administration'">
  <ap-icon-administration [class]="cssClass"></ap-icon-administration>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-base-fertilization'">
  <ap-icon-base-fertilization [class]="cssClass"></ap-icon-base-fertilization>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-booking'">
  <ap-icon-booking [class]="cssClass"></ap-icon-booking>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-collapse'">
  <ap-icon-collapse [class]="cssClass"></ap-icon-collapse>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-evaluation'">
  <ap-icon-evaluation [class]="cssClass"></ap-icon-evaluation>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-field-works'">
  <ap-icon-field-works [class]="cssClass"></ap-icon-field-works>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-fields'">
  <ap-icon-fields [class]="cssClass"></ap-icon-fields>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-import'">
  <ap-icon-import [class]="cssClass"></ap-icon-import>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-n-fertilization'">
  <ap-icon-n-fertilization [class]="cssClass"></ap-icon-n-fertilization>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-plant-protection'">
  <ap-icon-plant-protection [class]="cssClass"></ap-icon-plant-protection>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-seeding'">
  <ap-icon-seeding [class]="cssClass"></ap-icon-seeding>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-settings'">
  <ap-icon-settings [class]="cssClass"></ap-icon-settings>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-tasks'">
  <ap-icon-tasks [class]="cssClass"></ap-icon-tasks>
</ng-container>
<ng-container *ngIf="id === 'ap-icon-farm-records'">
  <ap-icon-farm-records [class]="cssClass"></ap-icon-farm-records>
</ng-container>
<ng-container *ngIf="id === 'ap-info-icon-round'">
  <ap-info-icon-round [class]="cssClass"></ap-info-icon-round>
</ng-container>
