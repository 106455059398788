import {ILegend, ILegendSelection, ILegendSelectionItem, ILegendValue} from '../../stores/map/map.legend.store';
import {ApDistributionClassColors}                                     from '../utils/ap-distribution-class-colors';
import {BehaviorSubject}                                               from 'rxjs';
import {MapStore}                                                      from '../../stores/map/map.store';
import {NFertilizationLegend}                                          from '../utils/n-fertilization-legend';
import {getMinFloat}                                                   from '../../ap-utils/service/value.calculations';
import {
  GetElementService
}                                                                      from '../../ap-utils/service/ap-get-element.service';
import {
  ApElementType
}                                                                      from '../../ap-interface/enums/ap-elements-type.enum';
import {
  LEGEND_IDS
}                                                                      from '../../ap-interface/enums/ap-legend-ids.enum';
import {
  BaseFertilizationService
}                                                                      from '../../services/data/base-fertilization.service';
import {FertilizerStore}                                               from '../../stores/base-data/fertilizer.store';
import {
  FertilizationManagementService
}                                                                      from '../../services/data/fertilization-management.service';
import {BaseFertilizationPlansLegendData}                              from './types/nutrient-planning.types';
import ICropTypes = Data.BaseData.ICropTypes;
import IMachineBreak = Data.DocuContext.Machine.IMachineBreak;

function getLastValueElementWithSelection(selectionList: ILegendSelection[], values: {
  [key: string]: ILegendValue[]
} | ILegendValue[] | ((...args: string[]) => ILegendValue[])): ILegendValue | null {
  const key = selectionList.Convert((item: any) => item.selectedValue.getValue().value).Join('_');
  if (!values[key]) {
    return null;
  }
  return values[key].LastOrDefault();
}

function getFirstValueElementWithSelection(selectionList: ILegendSelection[], values: {
  [key: string]: ILegendValue[]
} | ILegendValue[] | ((...args: string[]) => ILegendValue[])): ILegendValue | null {
  const key = selectionList.Convert((item: any) => item.selectedValue.getValue().value).Join('_');
  if (!values[key]) {
    return null;
  }
  return values[key].FirstOrDefault();
}


function getValueElementAtWithSelection(index: number, selectionList: ILegendSelection[], values: {
  [key: string]: ILegendValue[]
} | ILegendValue[] | ((...args: string[]) => ILegendValue[])): ILegendValue | null {
  const key = selectionList.Convert((item: any) => item.selectedValue.getValue().value).Join('_');
  if (!values[key]) {
    return null;
  }
  return values[key].ElementAt(index);
}

/**
 * Extracts all values encapsulated in the legend instance
 * @param selectionList the current selectionList (e.g. lead nutrient)
 * @param subjectList the subject list
 * @param values the legend's value object which might be an instance of different types depending on the legend type.
 */
function getValuesList(selectionList: ILegendSelection[], subjectList: BehaviorSubject<any>[], values: {
  [key: string]: ILegendValue[]
} | ILegendValue[] | ((...args: string[]) => ILegendValue[])): ILegendValue[] {
  const selectionListKeys = selectionList.map((item) => item.selectedValue.getValue() ? item.selectedValue.getValue().value : '');
  const subjectListKeys = subjectList.map((item) => item.getValue());
  let result: ILegendValue[] = null;
  if (Array.isArray(values)) {
    result = values;
  } else if (typeof values === 'object') {
    result = values[[...selectionListKeys, ...subjectListKeys].join('_')];
  } else if (typeof values === 'function') {
    result = values(...selectionListKeys, ...subjectListKeys);
  }
  return result;
}

function getLastValueElement(values: ILegendValue[]): ILegendValue | null {
  return values.LastOrDefault();
}

function getFirstValueElement(values: ILegendValue[]): ILegendValue | null {
  return values.FirstOrDefault();
}

function getValueElementAt(index: number, values: ILegendValue[]): ILegendValue | null {
  return values.ElementAt(index);
}

const MAX_N_UPTAKE_LEGEND_VALUE = 250;

export const CROP_TYPE_LEGEND: (crops: ICropTypes[]) => ILegend = (crops) => {
  const values = crops.Convert((c: any) => ({
    title: c.Description,
    color: c.Color,
  } as ILegendValue));
  return {
    id: LEGEND_IDS.CROP_TYPE,
    title: 'Global__Crops',
    unit: '',
    selectionLists: [],
    subjects: [],
    values,
    updateValues: () => {},
    getValueElementAt: (idx) => getValueElementAt(idx, values),
    getFirstValueElement: () => getFirstValueElement(values),
    getLastValueElement: () => getLastValueElement(values),
    getValuesList: () => values
  };
};

export const SOIL_GROUP_LEGEND: (translate: (value: string, params?: string[]) => string) => ILegend = (translate) => {
  const values = [
    {title: '1 ' + translate('Soilgroup_description_1'), color: '#ffffb3'},
    {title: '2 ' + translate('Soilgroup_description_2'), color: '#f2cc33'},
    {title: '3 ' + translate('Soilgroup_description_3'), color: '#a6734d'},
    {title: '4 ' + translate('Soilgroup_description_4'), color: '#267300'},
    {title: '5 ' + translate('Soilgroup_description_5'), color: '#0070ff'},
    {title: '6 ' + translate('Soilgroup_description_6'), color: '#b34d80'},
  ];
  return {
    id: LEGEND_IDS.SOIL_GROUP,
    title: 'Nutrients__Soilgroup',
    unit: '',
    selectionLists: [],
    subjects: [],
    values,
    updateValues: () => {},
    getValueElementAt: (idx) => getValueElementAt(idx, values),
    getFirstValueElement: () => getFirstValueElement(values),
    getLastValueElement: () => getLastValueElement(values),
    getValuesList: () => values
  };
};

const NUTRIENT_COLORS: ILegendValue[] = [
  {title: 'A-', color: ApDistributionClassColors.getColorsFromValue(1)},
  {title: 'A+', color: ApDistributionClassColors.getColorsFromValue(2)},
  {title: 'B-', color: ApDistributionClassColors.getColorsFromValue(3)},
  {title: 'B+', color: ApDistributionClassColors.getColorsFromValue(4)},
  {title: 'C-', color: ApDistributionClassColors.getColorsFromValue(5)},
  {title: 'C+', color: ApDistributionClassColors.getColorsFromValue(6)},
  {title: 'D-', color: ApDistributionClassColors.getColorsFromValue(7)},
  {title: 'D+', color: ApDistributionClassColors.getColorsFromValue(8)},
  {title: 'E', color: ApDistributionClassColors.getColorsFromValue(9)},
];

export const NUTRIENT_DISTRIBUTION: () => ILegend = () => {
  const values = {
    p: NUTRIENT_COLORS,
    k: NUTRIENT_COLORS,
    mg: NUTRIENT_COLORS,
    ph: NUTRIENT_COLORS,
  };
  const selectionLists = [
    {
      selectedValue: new BehaviorSubject<ILegendSelectionItem>({value: 'p', text: 'P'}),
      values: [
        {value: 'p', text: 'P'},
        {value: 'k', text: 'K'},
        {value: 'mg', text: 'Mg'},
        {value: 'ph', text: 'pH'},
      ],
    },
  ];
  const subjects = [];
  return {
    id: LEGEND_IDS.NUTRIENT_DISTRIBUTION,
    title: 'Global__NutrientDistribution',
    unit: '',
    selectionLists,
    subjects,
    values,
    updateValues: () => {},
    getValueElementAt: (idx) => getValueElementAtWithSelection(idx, selectionLists, values),
    getFirstValueElement: () => getFirstValueElementWithSelection(selectionLists, values),
    getLastValueElement: () => getLastValueElementWithSelection(selectionLists, values),
    getValuesList: () => getValuesList(selectionLists, subjects, values)
  };
};

export const TRACK_LEGEND: (ms: MapStore, mb: IMachineBreak[], tr: (value: string, params?: string[]) => string) => ILegend = (mapStore, breaks, translate) => {
  const firstItem = breaks.FirstOrDefault(_ => true);
  if (!firstItem) {
    return undefined;
  }
  const machineTypeSelection = {
    selectedValue: new BehaviorSubject<ILegendSelectionItem>(null),
    values: [],
  };

  for (const br of breaks) {
    const val = br.Kind.toString();
    if (machineTypeSelection.values.Any(_ => _.value === val)) {
      continue;
    }
    machineTypeSelection.values.Add({value: val, text: translate(br.Description)});
  }
  machineTypeSelection.selectedValue.next(machineTypeSelection.values.FirstOrDefault(_ => _.value === '1'));

  const machinePropertySelection = {
    selectedValue: new BehaviorSubject<ILegendSelectionItem>(null),
    values: [],
  };
  for (const br of breaks) {
    if (machinePropertySelection.values.Any(_ => _.value === br.Property)) {
      continue;
    }
    machinePropertySelection.values.Add({value: br.Property, text: translate(`Api_Enums__${br.Property}`)});
  }
  machinePropertySelection.selectedValue.next(machinePropertySelection.values.FirstOrDefault(_ => _.value === 'Speed'));

  const colors: { [key: string]: ILegendValue[] } = {};
  for (const br of breaks) {
    const key = `${br.Kind}_${br.Property}`;
    if (!colors[key]) {
      colors[key] = [];
    }
    colors[key].Add({color: br.Color, title: br.Value.toString()});
  }

  const selectionLists = [machineTypeSelection, machinePropertySelection];
  const subjects = [];
  const tmp: ILegend = {
    id: LEGEND_IDS.TRACK,
    title: 'Docu__Driving_Lanes',
    unit: 'Settings__Lbl_Menu_Unit',
    selectionLists,
    subjects,
    values: colors,
    updateValues: () => {
    },
    getValueElementAt: (idx) => getValueElementAtWithSelection(idx, selectionLists, colors),
    getFirstValueElement: () => getFirstValueElementWithSelection(selectionLists, colors),
    getLastValueElement: () => getLastValueElementWithSelection(selectionLists, colors),
    getValuesList: () => getValuesList(selectionLists, subjects, colors)
  };

  // update TrackLayer Style
  mapStore.Layers.onChangeTrackLayerStyle.emit({
    legend: tmp,
    selectionKeys: tmp.selectionLists.map(_ => _.selectedValue.getValue().value),
  });

  // TODO: unit is only a Placeholder the Unit handling is not implemented!
  return tmp;
};

export class INFertilisationStatisticValue {
  Average: number;
  ElementId: number;
  ProductId: number;
  Max: number;
  Min: number;
}

export const N_PLANNING: (translate: (value: string, params?: string[]) => string, fertilizationManagementService: FertilizationManagementService, fertilizerStore: FertilizerStore, stats: INFertilisationStatisticValue) => ILegend = (translate, fertilizationManagementService, fertilizerStore, stats) => {
  let leadNutrientsSelection = [];
  const colors: { [key: string]: ILegendValue[] } = {};
  const _leadNutrientsSelection = [];
  const unitMapped = {n: translate('Global__Unit_kgN_ha')};

  _leadNutrientsSelection.Add({
    value: 'n',
    text: 'N',
    suffix: unitMapped['n']
  });
  if (!colors['n']) {
    colors['n'] = [];
  }
  // if const
  if (stats.Min === stats.Max) {
    stats.Min = Math.max(Math.floor(stats.Max / 10), 1);
  }
  colors['n'] = N_PLANNING_COLORS(stats.Min, stats.Max, (stats.Max - stats.Min) / 9);

  const fertilizer = fertilizerStore.getFertilizer(stats.ProductId);
  if (fertilizer) {
    const fertilizerContent = fertilizationManagementService.getFertilizerContentN(fertilizer.Id);
    const newStat = new INFertilisationStatisticValue();
    newStat.Min = stats.Min / fertilizerContent;
    newStat.Max = stats.Max / fertilizerContent;
    newStat.Average = stats.Average / fertilizerContent;
    unitMapped['N'] = fertilizer.Unit.Description + translate('Global__UnitPerHa');
    _leadNutrientsSelection.Add({
      value: 'N',
      text: fertilizer.Prodname,
      transformFunction(value: number): number {
        return value / fertilizerContent;
      },
      suffix: unitMapped['N']
    });
    colors['N'] = N_PLANNING_COLORS(newStat.Min, newStat.Max, (newStat.Max - newStat.Min) / 9);
  }

  leadNutrientsSelection = [
    {
      selectedValue: new BehaviorSubject<ILegendSelectionItem>(_leadNutrientsSelection.FirstOrDefault()),
      values: _leadNutrientsSelection,
    }
  ];
  const subjects = [];
  return {
    id: LEGEND_IDS.N_PLANNING,
    title: 'Global__Planning',
    unit: unitMapped,
    selectionLists: leadNutrientsSelection,
    subjects,
    values: colors,
    updateValues: () => {},
    getValueElementAt: (idx) => getValueElementAtWithSelection(idx, leadNutrientsSelection, colors),
    getFirstValueElement: () => getFirstValueElementWithSelection(leadNutrientsSelection, colors),
    getLastValueElement: () => getLastValueElementWithSelection(leadNutrientsSelection, colors),
    getValuesList: () => getValuesList(leadNutrientsSelection, subjects, colors)
  };
};

/**
 * dynamic n-uptake legend based on farm's min/max values
 */
export const N_UPTAKE_LEGEND: (stats: { Min: number, Max: number }) => ILegend = (stats) => {
  const nUptakeColors = [
    'rgba(134, 86, 6, 1.00)',
    'rgba(207, 127, 23, 1.00 )',
    'rgba(239, 161, 59, 1.00 )',
    'rgba(248, 231, 28, 1.00 )',
    'rgba(253, 255, 206, 1.00 )',
    'rgba(189, 233, 158, 1.00 )',
    'rgba(90, 189, 97, 1.00 )',
    'rgba(7, 147, 77, 1.00 )',
    'rgba(5, 90, 91, 1.00 )',
    'rgba(22, 65, 102, 1.00 )',
    'rgba(8, 5, 91, 1.00 )',
  ];
  const statsMax = stats.Max > MAX_N_UPTAKE_LEGEND_VALUE ? MAX_N_UPTAKE_LEGEND_VALUE : stats.Max;
  const stepSize: number = Math.round((statsMax - stats.Min) / (nUptakeColors.length - 1));
  const values: ILegendValue[] = [];
  for (let i = 0; i < nUptakeColors.length; i++) {
    let legendValue = 0;
    switch (i) {
      case 0:
        legendValue = stats.Min;
        break;
      case (nUptakeColors.length - 1):
        legendValue = statsMax;
        break;
      default:
        legendValue = stats.Min + (i * stepSize);
        break;
    }
    values.push({
      color: nUptakeColors[i],
      value: legendValue,
      title: Math.round(legendValue)?.toString()
    });
  }

  return {
    id: LEGEND_IDS.N_UPTAKE,
    title: 'NUptake_Sat',
    unit: '',
    selectionLists: [],
    subjects: [],
    values,
    updateValues: () => {},
    getLastValueElement: () => getLastValueElement(values),
    getFirstValueElement: () => getFirstValueElement(values),
    getValueElementAt: (idx) => getValueElementAt(idx, values),
    getValuesList: () => values
  };
};


export const NUTRIENT_PLANNING: (translate: (value: string, params?: string[]) => string,
                                 elementService: GetElementService,
                                 baseFertilizationService: BaseFertilizationService,
                                 fertilizerStore: FertilizerStore,
                                 plansLegendData: BaseFertilizationPlansLegendData) => ILegend = (translate, elementService, baseFertilizationService, fertilizerStore, plansLegendData) => {
  let leadNutrientsSelection = [];
  const colors: { [key: string]: ILegendValue[] } = {};
  const _leadNutrientsSelection = [];
  const unitMapped = {};
  const statistics = Object.assign([], plansLegendData.Statistic);
  const fertilizer = fertilizerStore.getFertilizer(plansLegendData.ProductId);
  const statInProduct = baseFertilizationService.addProductAmountStatistic(plansLegendData.ProductId,
    plansLegendData.ElementId, statistics);

  // const statInProduct = summary.Statistic;
  statInProduct?.forEach(_ => {
    if (_.Max <= 0) {
      return;
    }
    const stepSize: number = (_.Max - _.Min) / 9;
    let elementValue: string;
    let elementText: string;
    let suffix: string;
    let stepForTitle: number;
    let transformFunction: (value: number) => number;
    const stepTitle = stepSize > 0 ? stepSize : _.Max;
    const absElementId = _.ElementId < 0 ? -_.ElementId : _.ElementId;
    switch (absElementId) {
      case ApElementType.P:
        elementValue = 'p';
        elementText = elementService.GetElementStringById(ApElementType.P);
        stepForTitle = elementService.CalculateElementOxidValueByElementType(stepTitle, ApElementType.P);
        transformFunction = (value: number): number => elementService.CalculateElementOxidValueByElementType(value, ApElementType.P);
        break;
      case ApElementType.K:
        elementValue = 'k';
        elementText = elementService.GetElementStringById(ApElementType.K);
        stepForTitle = elementService.CalculateElementOxidValueByElementType(stepTitle, ApElementType.K);
        transformFunction = (value: number): number => elementService.CalculateElementOxidValueByElementType(value, ApElementType.K);
        break;
      case ApElementType.Mg:
        elementValue = 'mg';
        elementText = elementService.GetElementStringById(ApElementType.Mg);
        stepForTitle = elementService.CalculateElementOxidValueByElementType(stepTitle, ApElementType.Mg);
        transformFunction = (value: number): number => elementService.CalculateElementOxidValueByElementType(value, ApElementType.Mg);
        break;
      case ApElementType.CaO:
        elementValue = 'ph';
        elementText = elementService.GetElementStringById(ApElementType.CaO);
        stepForTitle = stepTitle; // For CaO there is no necessary converting between element and oxid
        break;
    }

    // Using negative value here (created in addProductAmountStatistic) to differentiate between product and element
    // and to avoid any changes at mapFactory level elementValue.toUpperCase() is used, so when its product - we are
    // using uppercased element like P or PH
    if (_.ElementId < 0) {
      elementValue = elementValue.toUpperCase();
      elementText = fertilizer.Prodname;
      transformFunction = function (value: number): number {
        return baseFertilizationService.calculateProductAmount(plansLegendData.ProductId, plansLegendData.ElementId, value);
      };
      suffix = fertilizer.Unit.Description + translate('Global__UnitPerHa');
      stepForTitle = stepTitle; // No need for oxide calculation in case of product
    }

    if (elementValue === undefined || elementText === undefined || stepForTitle === undefined) {
      return;
    }
    _leadNutrientsSelection.Add({
      value: elementValue,
      text: elementText,
      transformFunction,
      suffix
    });
    unitMapped[elementValue] = suffix ? suffix : translate('Unit_kg_per_ha');

    if (!colors[elementValue]) {
      colors[elementValue] = [];
    }
    if (_.ElementId < 0) {
      const origElement = statInProduct.find(st => st.ElementId === absElementId);
      const origStepSize: number = (origElement.Max - origElement.Min) / 9;
      // Passing original max here because it is only used while calculating is legend should be static 0..9 or not
      colors[elementValue] = NUTRIENT_PLANNING_COLORS(origElement.Min, _.Max, origStepSize, stepForTitle);
    } else {
      colors[elementValue] = NUTRIENT_PLANNING_COLORS(_.Min, _.Max, stepSize, stepForTitle);
    }
  });

  leadNutrientsSelection = [
    {
      selectedValue: new BehaviorSubject<ILegendSelectionItem>(_leadNutrientsSelection.FirstOrDefault()),
      values: _leadNutrientsSelection,
    }
  ];
  const subjects = [];
  return {
    id: LEGEND_IDS.NUTRIENT_PLANNING,
    title: 'Global__Planning',
    unit: unitMapped,
    selectionLists: leadNutrientsSelection,
    subjects,
    values: colors,
    updateValues: () => {},
    getValueElementAt: (idx) => getValueElementAtWithSelection(idx, leadNutrientsSelection, colors),
    getFirstValueElement: () => getFirstValueElementWithSelection(leadNutrientsSelection, colors),
    getLastValueElement: () => getLastValueElementWithSelection(leadNutrientsSelection, colors),
    getValuesList: () => getValuesList(leadNutrientsSelection, subjects, colors)
  };
};

const NEED_COLORS = (max: number, step: number, stepTitle: number): ILegendValue[] => [
  {
    color: 'rgb(255, 230, 230)',
    title: '0',
    value: 0
  },
  {
    color: 'rgb(255, 199, 199)',
    title: (max <= 9 ? '1' : Math.round(stepTitle)).toString(),
    value: (max <= 9 ? 1 : Math.round(step))
  },
  {
    color: 'rgb(255, 142, 142)',
    title: (max <= 9 ? '2' : Math.round(stepTitle * 2)).toString(),
    value: (max <= 9 ? 2 : Math.round(step * 2))
  },
  {
    color: 'rgb(255, 85, 85)',
    title: (max <= 9 ? '3' : Math.round(stepTitle * 3)).toString(),
    value: (max <= 9 ? 3 : Math.round(step * 3))
  },
  {
    color: 'rgb(255, 28, 28)',
    title: (max <= 9 ? '4' : Math.round(stepTitle * 4)).toString(),
    value: (max <= 9 ? 4 : Math.round(step * 4))
  },
  {
    color: 'rgb(227, 0, 0)',
    title: (max <= 9 ? '5' : Math.round(stepTitle * 5)).toString(),
    value: (max <= 9 ? 5 : Math.round(step * 5))
  },
  {
    color: 'rgb(170, 0, 0)',
    title: (max <= 9 ? '6' : Math.round(stepTitle * 6)).toString(),
    value: (max <= 9 ? 6 : Math.round(step * 6))
  },
  {
    color: 'rgb(113, 0, 0)',
    title: (max <= 9 ? '7' : Math.round(stepTitle * 7)).toString(),
    value: (max <= 9 ? 7 : Math.round(step * 7))
  },
  {
    color: 'rgb(56, 0, 0)',
    title: (max <= 9 ? '8' : Math.round(stepTitle * 8)).toString(),
    value: (max <= 9 ? 8 : Math.round(step * 8))
  },
  {
    color: 'rgb(0, 0, 0)',
    title: (max <= 9 ? '9' : Math.round(stepTitle * 9)).toString(),
    value: (max <= 9 ? 9 : Math.round(step * 8) + getMinFloat(Math.round(step * 8)))
  },
];

function calculateCurrentNeedSteps(step: number, classes: number, max: number): number {
  const newStep = Math.floor(max / classes);
  return newStep <= 0 ? step : newStep;
}

export const NEED_LEGEND: (mapStore: MapStore, elementService: GetElementService, maxP: number, maxK: number, maxMg: number, maxCaO: number) => ILegend = (mapStore, elementService, maxP, maxK, maxMg, maxCaO) => {
  const stepP: number = calculateCurrentNeedSteps(10, 9, maxP);
  const stepK: number = calculateCurrentNeedSteps(10, 9, maxK);
  const stepMg: number = calculateCurrentNeedSteps(10, 9, maxMg);
  const stepCaO: number = calculateCurrentNeedSteps(50, 9, maxCaO);

  const stepPTitle: number = calculateCurrentNeedSteps(10, 9, elementService.CalculateElementOxidValueByElementType(maxP, ApElementType.P));
  const stepKTitle: number = calculateCurrentNeedSteps(10, 9, elementService.CalculateElementOxidValueByElementType(maxK, ApElementType.K));
  const stepMgTitle: number = calculateCurrentNeedSteps(10, 9, elementService.CalculateElementOxidValueByElementType(maxMg, ApElementType.Mg));
  const stepCaOTitle: number = stepCaO; // For CaO there is no necessary converting between element and oxid

  const values: { [key: string]: ILegendValue[] } = {
    '1_p': NEED_COLORS(maxP, stepP, stepPTitle),
    '1_k': NEED_COLORS(maxK, stepK, stepKTitle),
    '1_mg': NEED_COLORS(maxMg, stepMg, stepMgTitle),
    '1_ph': NEED_COLORS(maxCaO, stepCaO, stepCaOTitle),
    '2_p': NEED_COLORS(maxP, stepP, stepPTitle),
    '2_k': NEED_COLORS(maxK, stepK, stepKTitle),
    '2_mg': NEED_COLORS(maxMg, stepMg, stepMgTitle),
    '2_ph': NEED_COLORS(maxCaO, stepCaO, stepCaOTitle),
    '3_p': NEED_COLORS(maxP, stepP, stepPTitle),
    '3_k': NEED_COLORS(maxK, stepK, stepKTitle),
    '3_mg': NEED_COLORS(maxMg, stepMg, stepMgTitle),
    '3_ph': NEED_COLORS(maxCaO, stepCaO, stepCaOTitle)
  };
  const nutrients = [
    {
      value: 'p',
      text: elementService.GetElementStringByString(ApElementType.P),
      transformFunction(value: number): number {
        return elementService.CalculateElementOxidValueByElementType(value, ApElementType.P);
      }
    },
    {
      value: 'k',
      text: elementService.GetElementStringByString(ApElementType.K),
      transformFunction(value: number): number {
        return elementService.CalculateElementOxidValueByElementType(value, ApElementType.K);
      }
    },
    {
      value: 'mg',
      text: elementService.GetElementStringByString(ApElementType.Mg),
      transformFunction(value: number): number {
        return elementService.CalculateElementOxidValueByElementType(value, ApElementType.Mg);
      }
    },
    {
      value: 'ph',
      text: elementService.GetElementStringByString(ApElementType.CaO),
      transformFunction(value: number): number {
        return elementService.CalculateElementOxidValueByElementType(value, ApElementType.CaO);
      }
    }
  ];
  const selectionLists = [
    {
      selectedValue: new BehaviorSubject<ILegendSelectionItem>({value: '1', text: 'Global__Crop_Rotation_Need'}),
      values: NEEDS,
    },
    {
      selectedValue: new BehaviorSubject<ILegendSelectionItem>(nutrients.FirstOrDefault()),
      values: nutrients
    }
  ];
  const subjects = [];
  const tmp: ILegend = {
    id: LEGEND_IDS.NEED,
    title: 'Nutrients__Need',
    unit: 'kg/ha',
    selectionLists,
    subjects,
    values,
    updateValues: () => {},
    getValueElementAt: (idx) => getValueElementAtWithSelection(idx, selectionLists, values),
    getFirstValueElement: () => getFirstValueElementWithSelection(selectionLists, values),
    getLastValueElement: () => getLastValueElementWithSelection(selectionLists, values),
    getValuesList: () => getValuesList(selectionLists, subjects, values)
  };

  mapStore.Layers.NeedLegendChange.emit({
    legend: tmp,
    selectionKeys: tmp.selectionLists.map(_ => _.selectedValue.getValue().value),
  });
  return tmp;
};

const N_PLANNING_COLORS = (min: number, max: number, step: number) => [
  {
    color: 'rgba(243, 243, 216, 1)',
    title: '0',
    value: 0
  },
  {
    color: 'rgb(225, 242, 215 )',
    title: (max <= 9 ? '1' : Math.floor(min)).toString(),
    value: (max <= 9 ? 1 : Math.floor(min))
  },
  {
    color: 'rgb(195, 229, 227 )',
    title: (max <= 9 ? '2' : Math.floor(min + step)).toString(),
    value: (max <= 9 ? 2 : Math.floor(min + step))
  },
  {
    color: 'rgb(165, 216, 239 )',
    title: (max <= 9 ? '3' : Math.floor(min + step * 2)).toString(),
    value: (max <= 9 ? 3 : Math.floor(min + step * 2))
  },
  {
    color: 'rgb(135, 203, 251 )',
    title: (max <= 9 ? '4' : Math.floor(min + step * 3)).toString(),
    value: (max <= 9 ? 4 : Math.floor(min + step * 3))
  },
  {
    color: 'rgb(108, 174, 229 )',
    title: (max <= 9 ? '5' : Math.floor(min + step * 4)).toString(),
    value: (max <= 9 ? 5 : Math.floor(min + step * 4))
  },
  {
    color: 'rgb(83, 136, 190 )',
    title: (max <= 9 ? '6' : Math.floor(min + step * 5)).toString(),
    value: (max <= 9 ? 6 : Math.floor(min + step * 5))
  },
  {
    color: 'rgb(57, 99, 150 )',
    title: (max <= 9 ? '7' : Math.floor(min + step * 6)).toString(),
    value: (max <= 9 ? 7 : Math.floor(min + step * 6))
  },
  {
    color: 'rgb(32, 61, 111 )',
    title: (max <= 9 ? '8' : Math.floor(min + step * 7)).toString(),
    value: (max <= 9 ? 8 : Math.floor(min + step * 7))
  },
  {
    color: 'rgb(7, 24, 71 )',
    title: (max <= 9 ? '9' : Math.floor(min + step * 8)).toString(),
    value: (max <= 9 ? 9 : Math.floor(min + step * 8))
  },
];

const NUTRIENT_PLANNING_COLORS = (min: number, max: number, step: number, stepTitle: number) => [
  {
    color: 'rgba(243, 243, 216, 1)',
    title: '0',
    value: 0
  },
  {
    color: 'rgb(225, 242, 215 )',
    title: (max <= 9 ? '1' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 1))).toString(),
    value: (max <= 9 ? 1 : Math.round(nutrientPlanningValue(min, step, 1)))
  },
  {
    color: 'rgb(195, 229, 227 )',
    title: (max <= 9 ? '2' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 2))).toString(),
    value: (max <= 9 ? 2 : Math.round(nutrientPlanningValue(min, step, 2)))
  },
  {
    color: 'rgb(165, 216, 239 )',
    title: (max <= 9 ? '3' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 3))).toString(),
    value: (max <= 9 ? 3 : Math.round(nutrientPlanningValue(min, step, 3)))
  },
  {
    color: 'rgb(135, 203, 251 )',
    title: (max <= 9 ? '4' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 4))).toString(),
    value: (max <= 9 ? 4 : Math.round(nutrientPlanningValue(min, step, 4)))
  },
  {
    color: 'rgb(108, 174, 229 )',
    title: (max <= 9 ? '5' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 5))).toString(),
    value: (max <= 9 ? 5 : Math.round(nutrientPlanningValue(min, step, 5)))
  },
  {
    color: 'rgb(83, 136, 190 )',
    title: (max <= 9 ? '6' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 6))).toString(),
    value: (max <= 9 ? 6 : Math.round(nutrientPlanningValue(min, step, 6)))
  },
  {
    color: 'rgb(57, 99, 150 )',
    title: (max <= 9 ? '7' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 7))).toString(),
    value: (max <= 9 ? 7 : Math.round(nutrientPlanningValue(min, step, 7)))
  },
  {
    color: 'rgb(32, 61, 111 )',
    title: (max <= 9 ? '8' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 8))).toString(),
    value: (max <= 9 ? 8 : Math.round(nutrientPlanningValue(min, step, 8)))
  },
  {
    color: 'rgb(7, 24, 71 )',
    title: (max <= 9 ? '9' : Math.round(nutrientPlanningTitle(min, step, stepTitle, 9))).toString(),
    value: (max <= 9 ? 9 : Math.round(nutrientPlanningValue(min, step, 9)))
  }
];

const minStepSize = 2.5;

const nutrientPlanningTitle = (min: number, step: number, stepTitle: number, level: number): number => {
  if (level <= 0) {
    return 0;
  }
  if (step === 0) {
    return stepTitle;
  }
  return min * (stepTitle / step) + stepTitle * level;
};

const nutrientPlanningValue = (min: number, step: number, level: number): number => {
  if (level <= 0) {
    return 0;
  }
  return min + step * level;
};

export const NEEDS = [
  {value: '1', text: 'Global__Crop_Rotation_Need'},
  {value: '2', text: 'Global__Remaining_Need_One'},
  {value: '3', text: 'Global__Remaining_Need_Two'}
];

export const N_LOG_INTERPOLATION_LEGEND: () => ILegend = () => {
  const valuesToSelect = [
    {value: '0', text: 'N__Uptake', suffix: 'Global__Unit_kgN_ha'},
    {value: '1', text: 'N__Application', suffix: 'Global__Unit_kgN_ha'},
    {value: '2', text: 'N__GBI', suffix: undefined}
  ];
  const selectedValue0 = new BehaviorSubject<ILegendSelectionItem>(valuesToSelect[0]);
  const values: (...args: string[]) => ILegendValue[] = (band: string, min: string, max: string) => {
    let minValue = Math.max(+min, 0);
    let maxValue = +max;
    if (isNaN(minValue)) {
      minValue = 0;
    }
    if (isNaN(maxValue)) {
      maxValue = 0;
    }
    const colors = NFertilizationLegend.N_COLORS[+band];
    const step = Math.max((maxValue - minValue) / colors.length, 1);
    return colors.map((color, i) => {
      const value = (step < 10 ? Math.floor((minValue + step * i) * 10) / 10 : Math.floor((minValue + step * i)));
      const legendValue: ILegendValue = {
        color,
        title: '' + Math.trunc(value),
        value
      };
      return legendValue;
    });
  };

  const selectionLists = [
    {
      selectedValue: selectedValue0,
      values: valuesToSelect,
      disabled: false
    }
  ];
  const subjects = [new BehaviorSubject<number>(0), new BehaviorSubject<number>(0)];
  return {
    id: LEGEND_IDS.N_LOG_INTERPOLATION,
    title: 'Lbl_NSensor',
    unit: '',
    selectionLists,
    subjects,
    values,
    updateValues: () => {
    },
    getLastValueElement: () => getLastValueElementWithSelection(selectionLists, values),
    getFirstValueElement: () => getFirstValueElementWithSelection(selectionLists, values),
    getValueElementAt: (idx) => getValueElementAtWithSelection(idx, selectionLists, values),
    getValuesList: () => getValuesList(selectionLists, subjects, values)
  };
};

export const PP_LOG_INTERPOLATION_LEGEND: () => ILegend = () => {
  const valuesToSelect = [
    {value: '0', text: 'N__Uptake', suffix: 'Global__Unit_kgN_ha'},
    {value: '1', text: 'Base_PP_Type', suffix: 'Unit_l_per_ha'},
    {value: '2', text: 'N__GBI', suffix: undefined}
  ];
  const selectedValue = new BehaviorSubject<ILegendSelectionItem>(valuesToSelect[0]);
  const values: (...args: string[]) => ILegendValue[] = (band: string, min: string, max: string) => {
    let minValue = Math.max(+min, 0);
    let maxValue = +max;
    if (isNaN(minValue)) {
      minValue = 0;
    }
    if (isNaN(maxValue)) {
      maxValue = 0;
    }
    const colors = NFertilizationLegend.N_COLORS[+band];
    const step = Math.max((maxValue - minValue) / colors.length, 1);
    return colors.map((color, i) => {
      const value = (step < 10 ? Math.floor((minValue + step * i) * 10) / 10 : Math.floor((minValue + step * i)));
      const legendValue: ILegendValue = {
        color,
        title: '' + Math.trunc(value),
        value
      };
      return legendValue;
    });
  };

  const selectionLists = [
    {
      selectedValue,
      values: valuesToSelect,
      disabled: false
    }
  ];
  const subjects = [new BehaviorSubject<number>(0), new BehaviorSubject<number>(0)];
  const result: ILegend = {
    id: LEGEND_IDS.PP_LOG_INTERPOLATION,
    title: 'Lbl_NSensor',
    unit: '',
    selectionLists,
    subjects,
    values,
    updateValues: () => {
    },
    getLastValueElement: () => getLastValueElementWithSelection(selectionLists, values),
    getFirstValueElement: () => getFirstValueElementWithSelection(selectionLists, values),
    getValueElementAt: (idx) => getValueElementAtWithSelection(idx, selectionLists, values),
    getValuesList: () => getValuesList(selectionLists, subjects, values)
  };
  return result;
};

export const LOG_LEGEND: () => ILegend = () => {
  const min = new BehaviorSubject<number>(0);
  const max = new BehaviorSubject<number>(10);
  const values = () => {
    return NFertilizationLegend.getDynamicColorLegend('uptake', min.getValue(), max.getValue(), minStepSize);
  };

  return {
    id: LEGEND_IDS.LOG,
    title: 'NUptake',
    unit: '',
    selectionLists: [],
    subjects: [],
    values,
    updateValues: (minValue: number, maxValue: number) => {
      min.next(minValue);
      max.next(maxValue > MAX_N_UPTAKE_LEGEND_VALUE ? MAX_N_UPTAKE_LEGEND_VALUE : maxValue);
    },
    getLastValueElement: () => getLastValueElement(values()),
    getFirstValueElement: () => getFirstValueElement(values()),
    getValueElementAt: (idx) => getValueElementAt(idx, values()),
    getValuesList: () => values()
  };
};

export const NDI_LEGEND: () => ILegend = () => {
  const values = [
    {title: '0', value: 0, color: '#ffffff'},
    {title: '10', value: 10, color: '#865606'},
    {title: '20', value: 20, color: '#efa13b'},
    {title: '30', value: 30, color: '#faf50c'},
    {title: '40', value: 40, color: '#bde99e'},
    {title: '50', value: 50, color: '#5abd61'},
    {title: '60', value: 60, color: '#07934d'},
    {title: '70', value: 70, color: '#164166'},
    {title: '80', value: 80, color: '#4a0957'},
    {title: '90', value: 90, color: '#e40c81'},
    {title: '100', value: 100, color: '#e40c3b'},
  ];
  return {
    id: LEGEND_IDS.NDI,
    title: 'Global__NDI_Name',
    unit: '',
    selectionLists: [],
    subjects: [],
    values,
    updateValues: () => {},
    getLastValueElement: () => getLastValueElement(values),
    getFirstValueElement: () => getFirstValueElement(values),
    getValueElementAt: (idx) => getValueElementAt(idx, values),
    getValuesList: () => values
  };
};

