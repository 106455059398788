import {EventEmitter}                       from '@angular/core';
import {BehaviorSubject, combineLatest}     from 'rxjs';
import {filter}                             from 'rxjs/operators';
import {ListSortOrder, SafeBehaviorSubject} from 'ts-tooling';
import {MapStore}                           from './map.store';
import {
  CROP_TYPE_LEGEND,
  LOG_LEGEND,
  N_LOG_INTERPOLATION_LEGEND,
  N_PLANNING,
  N_UPTAKE_LEGEND,
  NDI_LEGEND,
  NEED_LEGEND,
  NUTRIENT_DISTRIBUTION,
  NUTRIENT_PLANNING,
  PP_LOG_INTERPOLATION_LEGEND,
  SOIL_GROUP_LEGEND,
  TRACK_LEGEND
}                                           from '../../ap-map/layers/layer.legends';
import {TranslationStore}                   from '../translation/translation.store';
import {GetElementService}                  from '../../ap-utils/service/ap-get-element.service';
import {BaseFertilizationService}           from '../../services/data/base-fertilization.service';
import {FertilizerStore}                    from '../base-data/fertilizer.store';
import {FertilizationManagementService}     from '../../services/data/fertilization-management.service';
import {SettingsStore}                      from '../base-data/settings.store';
import {BaseFertilizationPlansLegendData}   from '../../ap-map/layers/types/nutrient-planning.types';
import ICropTypes = Data.BaseData.ICropTypes;
import IMachineBreak = Data.DocuContext.Machine.IMachineBreak;
import ICampaignYear = Data.Authentication.ICampaignYear;

export interface ILegendValue {
  title: string;
  color: string;
  value?: number;
}

export interface ILegendSelectionItem {
  value: string;
  text: string;
  transformFunction?: (value: number) => number;
  suffix?: string;
}

export interface ILegendSelection {
  selectedValue: BehaviorSubject<ILegendSelectionItem>;
  values: ILegendSelectionItem[] | ((...args: string[]) => ILegendSelectionItem[]);
  disabled?: boolean;
}

export interface ILegend {
  id: number;
  title: string;
  selectionLists: ILegendSelection[];
  subjects: BehaviorSubject<any>[];
  unit: { [key: string]: string } | string;
  values: { [key: string]: ILegendValue[] } | ILegendValue[] | ((...args: string[]) => ILegendValue[]);
  updateValues: (...args: any[]) => void;

  getLastValueElement(): ILegendValue;

  getFirstValueElement(): ILegendValue;

  getValueElementAt(index: number): ILegendValue | null;

  /**
   * Returns all current values from the legend
   */
  getValuesList(): ILegendValue[];
}

export interface ILegendState {
  Legends: ILegend[];
}

export class MapLegendStore {
  public static DefaultState: ILegendState = {
    Legends: [],
  };
  public onCropTypesChanged = new EventEmitter<ICropTypes[]>();
  public onMachineBreaksChanged = new EventEmitter<IMachineBreak[]>(true);
  public onCampaignYearChange = new EventEmitter<ICampaignYear>(true);
  public onNutrientPlanningChanged = new EventEmitter<BaseFertilizationPlansLegendData | undefined>(undefined);
  public onNPlanningChanged = new EventEmitter<any>(true);
  public onNUptakeChanged = new EventEmitter<{ Min: number, Max: number }>(true);

  private readonly _nLogInterpolationLegend: ILegend = null;
  private readonly _ppLogInterpolationLegend: ILegend = null;
  private readonly _LogLegend: ILegend = null;
  private readonly _ndiLegend: ILegend = null;
  private _cropTypeLegend: ILegend = null;
  private _soilGroupLegend: ILegend = null;
  private _trackLegend: ILegend = null;
  private _needLegend: ILegend = null;
  private _nutrientDistributionLegend: ILegend = null;
  private _nutrientPlanningLegend: ILegend = null;
  private _nNeedLegend: ILegend = null;
  private _tskMgmtLegend: ILegend = null;
  private _nPlanningLegend: ILegend = null;
  private _ppPlanningLegend: ILegend = null;
  private _nUptakeLegend: ILegend = null;

  constructor(private mapStore: MapStore,
              private settingsStore: SettingsStore,
              private translationStore: TranslationStore,
              private baseFertilizationService: BaseFertilizationService,
              private fertilizerStore: FertilizerStore,
              private elementService: GetElementService,
              public fertilizationManagementService: FertilizationManagementService) {
    this.createNutrientDistributionLegend();
    this.listenRefreshSoilGroupLegend();
    this.listenRefreshTrackLegend();
    this.listenRefreshNeedLegend();
    this.listenRefreshCropTypeLegend();
    this.listenRefreshNutrientPlanningLegend();
    this.listenRefreshNPlanningLegend();
    this.listenRefreshNUptakeLegend();
    this._nLogInterpolationLegend = N_LOG_INTERPOLATION_LEGEND();
    this._ppLogInterpolationLegend = PP_LOG_INTERPOLATION_LEGEND();
    this._LogLegend = LOG_LEGEND();
    this._ndiLegend = NDI_LEGEND();
  }

  public get All$(): SafeBehaviorSubject<ILegend[]> {
    return this.mapStore.Listen(s => s.legends.Legends);
  }

  public get All(): ILegend[] {
    return this.All$.getValue();
  }

  public get CropTypeLegend(): ILegend {
    return this._cropTypeLegend;
  }

  public get CropTypeLegendHasElements(): boolean {
    return Array.isArray(this.CropTypeLegend.values) && this.CropTypeLegend.values.length > 0;
  }

  public get SoilGroupLegend(): ILegend {
    return this._soilGroupLegend;
  }

  public get NutrientPlanningLegend(): ILegend {
    return this._nutrientPlanningLegend;
  }

  public get NPlanningLegend(): ILegend {
    return this._nPlanningLegend;
  }

  public get PpPlanningLegend(): ILegend {
    return this._ppPlanningLegend;
  }

  public get NutrientDistributionLegend(): ILegend {
    return this._nutrientDistributionLegend;
  }

  public get TrackLegend(): ILegend {
    return this._trackLegend;
  }

  public get NeedLegend(): ILegend {
    return this._needLegend;
  }

  public get LogLegend(): ILegend {
    return this._LogLegend;
  }

  public get nLogInterpolationLegend(): ILegend {
    return this._nLogInterpolationLegend;
  }

  public get PpLogInterpolationLegend(): ILegend {
    return this._ppLogInterpolationLegend;
  }

  public get NNeedLegend(): ILegend {
    return this._nNeedLegend;
  }

  public get NdiLegend(): ILegend {
    return this._ndiLegend;
  }

  public get NUptakeLegend(): ILegend {
    return this._nUptakeLegend;
  }

  public get TaskMgmtLegend(): ILegend {
    return this._tskMgmtLegend;
  }

  public set TaskMgmtLegend(value: ILegend) {
    this._tskMgmtLegend = value;
  }

  public addLegend(l: ILegend): void {
    if (!l) {
      return;
    }
    this.mapStore.Mutate(s => s.legends.Legends, (o) => {
      o.Replace((d) => d.id === l.id, l);
      return o;
    });
  }

  public removeLegend(l: ILegend): void {
    if (!l) {
      return;
    }
    this.mapStore.Mutate(s => s.legends.Legends, (o) => {
      o.RemoveAll((cl) => cl.title === l.title);
      return o;
    });
  }

  public refreshLegend(l: ILegend, state: boolean): void {
    if (!l) {
      return;
    }
    this.removeLegend(l);
    if (state) {
      this.addLegend(l);
    }
  }

  private listenRefreshCropTypeLegend(): void {
    this.onCropTypesChanged.subscribe((ct) => {
      this._cropTypeLegend = CROP_TYPE_LEGEND(ct.SortBy(['Description'], [ListSortOrder.ASC]));
      this.refreshLegend(this.CropTypeLegend, this.mapStore.Layers.FieldsCropLayer.Visibility && this.CropTypeLegendHasElements);
    });
  }

  private createNutrientDistributionLegend(): void {
    this._nutrientDistributionLegend = NUTRIENT_DISTRIBUTION();
    this.refreshLegend(this.NutrientDistributionLegend, this.mapStore.Layers.DistributionLayer.Visibility);
  }

  private listenRefreshNutrientPlanningLegend(): void {
    this.onNutrientPlanningChanged
      .subscribe(data => {
        if (!!data) {
          this._nutrientPlanningLegend = NUTRIENT_PLANNING(this.translationStore.FindTranslationForSelectedLanguage.bind(this.translationStore), this.elementService, this.baseFertilizationService, this.fertilizerStore, data);
          this.refreshLegend(this.NutrientPlanningLegend, this.mapStore.Layers.NutrientPlanningLayer.Visibility || this.mapStore.Layers.TaskManagementNutrientLayer.Visibility);
        } else {
          this.removeLegend(this.NutrientPlanningLegend);
          this._nutrientPlanningLegend = null;
        }
      });
  }

  private listenRefreshNUptakeLegend(): void {
    this.onNUptakeChanged
      .pipe(filter(stats => !!stats))
      .subscribe(stats => {
        this._nUptakeLegend = N_UPTAKE_LEGEND(stats);
        this.refreshLegend(this.NUptakeLegend, this.mapStore.Layers.NUptakeLayer.Visibility);
      });
  }

  private listenRefreshTrackLegend(): void {
    combineLatest([
      this.onMachineBreaksChanged.asObservable(),
      this.translationStore.Listen(s => s.loading),
    ])
      .pipe(
        filter(([, translationLoading]) => !translationLoading)
      )
      .subscribe(([machineBreaks]) => {
        this._trackLegend = TRACK_LEGEND(this.mapStore, machineBreaks, this.translationStore.FindTranslationForSelectedLanguage.bind(this.translationStore));
        this.refreshLegend(this.TrackLegend, this.mapStore.Layers.TrackLayer.Visibility);
      });
  }

  private listenRefreshNeedLegend(): void {
    combineLatest([
      this.onCampaignYearChange.asObservable(),
      this.settingsStore.FirstSetting$
    ]).pipe(filter(([year, settings]) => !!year && !!settings))
      .subscribe(([year, _]) => {
        this._needLegend = NEED_LEGEND(this.mapStore, this.elementService, year.MaxInitialNeedP, year.MaxInitialNeedK, year.MaxInitialNeedMg, year.MaxInitialNeedPh);
        this.refreshLegend(this.NeedLegend, this.mapStore.Layers.NeedLayer.Visibility);
      });
  }

  private listenRefreshNPlanningLegend(): void {
    this.onNPlanningChanged
      .pipe(filter(stats => !!stats))
      .subscribe(stats => {
        this._nPlanningLegend = N_PLANNING(this.translationStore.FindTranslationForSelectedLanguage.bind(this.translationStore), this.fertilizationManagementService, this.fertilizerStore, stats);
        this.refreshLegend(this.NPlanningLegend, this.mapStore.Layers.NApplicationMapLayer.Visibility);
      });
  }

  private listenRefreshSoilGroupLegend(): void {
    this.translationStore.Listen(s => s.loading)
      .pipe(filter((translationLoading) => !translationLoading))
      .subscribe(_ => {
        this._soilGroupLegend = SOIL_GROUP_LEGEND(this.translationStore.FindTranslationForSelectedLanguage.bind(this.translationStore));
        this.refreshLegend(this.SoilGroupLegend, this.mapStore.Layers.SoilGroupLayer.Visibility);
      });
  }
}
