import {Component, Input} from '@angular/core';

@Component({
  selector: 'inner-loading',
  template: `
    <div class="inner-loading-modal" *ngIf="visible">
      <div class="modal-content">
        <span class="{{iconClass}}" style="font-size: 64px;"></span>
        <p>{{text | translate}}</p>
        <button
          *ngIf="!!button"
          kendoButton
          class="k-button k-primary button-important"
          (click)="button?.action()"
        >
          {{button?.text | translate}}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .inner-loading-modal {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 9999;
      }
      .inner-loading-modal .modal-content {
        position: fixed;
        min-width: 220px;
        width: 30%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 10px;
        text-align: center;
        background: transparent;
      }
    `
  ],
})
export class InnerLoadingComponent {
  @Input()
  public visible = false;
  @Input()
  public text = '';
  @Input()
  public iconClass = 'k-i-loading k-icon';
  @Input()
  public button: { text: string, action: () => void } | undefined;
}
