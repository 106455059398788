import {Store}                                                                   from '../index';
import {Injectable}                                                              from '@angular/core';
import {
  ApCustomTypes,
  CampaignYearSelect,
  InformFarmChange,
  InformFarmChangeSuccess,
  LogoutFromSystem,
  MapFactoryAuthenticationLoad,
  MapFactoryAuthenticationSuccess,
  NetTypes,
  UserLeavePage,
  UserLogin,
  UserLoginDelay,
  UserLoginFail,
  UserLoginSuccess,
  UserResetRedirectToNewsSuccess,
  UserUpdateFullNameSuccess
}                                                                                from 'invoker-transport';
import {IApPermissionData, IStateStore}                                          from '../../ap-interface';
import {
  getNow
}                                                                                from '@progress/kendo-angular-dateinputs/dist/es2015/util';
import {APP_CONFIGURATION}                                                       from '../../ap-core/config';
import {NotifyStore}                                                             from '../dialog/notify.store';
import {Create, EventHandler, ObjectFactory, SafeBehaviorSubject, StringFactory} from 'ts-tooling';
import {RouterStore}                                                             from '../router/router.store';
import {
  AccessPermissionsStore
}                                                                                from '../settings/access.permission.store';
import {FarmUsersStore}                                                          from '../farm/farm.users.store';
import {CampaignYearStore}                                                       from './campaignyear.store';
import {combineLatest}                                                           from 'rxjs';
import {filter, map}                                                             from 'rxjs/operators';
import {
  FertilizerInorganicStore
}                                                                                from '../base-data/fertilizer.inorganic.store';
import {
  FertilizerOrganicStore
}                                                                                from '../base-data/fertilizer.organic.store';
import {CropGroupStore}                                                          from '../base-data/crop.groups.store';
import {CropTypeStore}                                                           from '../base-data/crop.types.store';
import {MachineStore}                                                            from '../docu/machine.store';
import {InstrumentStore}                                                         from '../docu/instrument.store';
import {SubFarmStore}                                                            from '../base-data/subfarm.store';
import {MachineLoggerStore}                                                      from '../docu/machine.logger.store';
import {LoggerStore}                                                             from '../docu/logger.store';
import {DriverStore}                                                             from '../docu/driver.store';
import {DriverMachineStore}                                                      from '../docu/driver.machine.store';
import {WorkTypesStore}                                                          from '../base-data/worrktypes.store';
import {UserStore}                                                               from '../base-data/user.store';
import {SettingsStore}                                                           from '../base-data/settings.store';
import {MenuStore}                                                               from '../layout/menu.store';
import {
  UserGroupsUserStore
}                                                                                from '../settings/user.group.user.store';
import {UserGroupStore}                                                          from '../settings/user.group.store';
import {UserSettingsStore}                                                       from '../settings/usersettings.store';
import {
  ParameterDefinitionStore
}                                                                                from '../parameter/parameter.definition.store';
import {NotificationStore}                                                       from '../common/notification.store';
import {ApMapInstance}                                                           from '../../ap-map';
import {FormStore}                                                               from '../layout/form.store';
import {MapStore}                                                                from '../map/map.store';
import {
  MapFactoryAuthenticator
}                                                                                from '../../map-factory/authentication';
import {KrigingStore}                                                            from '../administration/kriging.store';
import {ClientCache}                                                             from '../local-cache';
import {ApplModesStore}                                                          from '../common/appl-modes.store';
import {ElementsStore}                                                           from '../common/elements.store';
import {ExportFormatsStore}                                                      from '../common/export-formats.store';
import {ExportTargetsStore}                                                      from '../common/export-targets.store';
import {FactorsStore}                                                            from '../common/factors.store';
import {OperationModesStore}                                                     from '../common/operation-modes.store';
import {UnitsStore}                                                              from '../common/units.store';
import {mapFactoryStyler}                                                        from '../../map-factory/style';
import {
  AgriportSessionDuration,
  ApCookieService
}                                                                                from '../../ap-core/services/ap-cookie.service';
import {MachineBreaksStore}                                                      from '../docu/machine.breaks.store';
import {FieldStore}                                                              from '../farm/field.store';
import {CropRotationStore}                                                       from '../farm/crop.rotation.store';
import {JobsStore}                                                               from '../administration/jobs.store';
import {
  SoilSampleFieldStore
}                                                                                from '../evaluation/soilsample.field.store';
import {
  TranslationStore
}                                                                                from '../translation/translation.store';
import {FarmStore}                                                               from '../farm/farm.store';
import {
  ApRoleTypeService
}                                                                                from '../../services/common/ap-role-type.service';
import {LandUseStore}                                                            from '../base-data/landuse.store';
import {
  FieldNutrientDistributionStore
}                                                                                from '../nutrients/field-nutrient-distributions.store';
import {
  BasicFertilisationSummariesStore
}                                                                                from '../taskmanagement/basic.fertilisation.summaries.store';
import {LicenseStore}                                                            from '../farm/license.store';
import {NUptakeFactorsStore}                                                     from '../common/nuptake-factors.store';
import {LanguageStore}                                                           from '../translation/language.store';
import {NUptakeStore}                                                            from '../ndi/nuptake.store';
import {
  CampaignYearService
}                                                                                from '../../services/data/campaign-year.service';
import {ModalDialogStore}                                                        from '../dialog/modal.dialog.store';
import {ModalDialogButtonKeys, ModalDialogPresets}                               from '../dialog/modal.dialog.presets';
import {
  IModalDialogResult
}                                                                                from '../../ap-interface/interfaces/ap-modaldialog-data.interface';
import {
  ApFeatureModulKey
}                                                                                from '../../ap-interface/enums/ap-feature-modul-key.enum';
import {
  AgriportConstantsService
}                                                                                from '../../services/common/agriport-constants.service';
import {
  AgriportConstantsEnum,
  BaseFertLicenseAmountAbs
}                                                                                from '../../ap-interface/enums/ap-agriport-constants.enum';
import {
  EffectiveRangesStore
}                                                                                from '../common/effective_ranges.store';
import {
  GetRoundNumericService
}                                                                                from '../../ap-utils/service/get-round-numeric.service';
import {
  WorktypeUnitStore
}                                                                                from '../base-data/worktype-unit.store';
import {
  ApSignalrService
}                                                                                from '../../ap-core/services/ap-signalr.service';
import {
  ApBase64Util
}                                                                                from '../../ap-utils/static/ap-base64-util';
import {
  SoilSampleDateStore
} from '../nutrients/soilsampledate.store';
import {
  SoilSampleDateService
} from '../../services/data/soil-sample-date.service';
import IUser = Data.Authentication.IUser;
import ILogin = Data.Authentication.ILogin;
import IFarm = Data.Authentication.IFarm;
import IActiveUser = Data.Authentication.IActiveUser;
import IApValidationResult = Data.Api.Validation.IApValidationResult;
import IFarmUsersBase = Data.Authentication.IFarmUsersBase;
import LicenseAdjustAction = Data.Licensing.Enums.LicenseAdjustAction;
import ICampaignYear = Data.Authentication.ICampaignYear;
import IField = Data.FieldManagement.IField;
import ILicense = Data.Licensing.ILicense;

interface ILoginState extends IStateStore<IFarmUsersBase> {
  user: IUser;
  username: string;
  password: string;
  emailAddress: string;
  isLoggedIn: boolean;
  message: string;
  isEmailAddressSend: boolean;
  isSetPassword: boolean;
  loginTimestamp: string;
  isStaySignedIn: boolean;
  loadingProgress: number;
  maxProgress: number;
  showProgressBar: boolean;
  delay: number;
}

interface IFarmState extends IStateStore<IFarm> {
  selectedFarm: IFarm;
}

interface ILoginStore {
  login: ILoginState;
  farm: IFarmState;
  activeUsers: IStateStore<IActiveUser>;
  dataLoading: boolean;
  longOperationInProgress: boolean;
  clientErrorOccurred: boolean;
  isDebugModeEnabled: boolean;
  userPermissions: IApPermissionData;
}

@Injectable({providedIn: 'root'})
export class LoginStore extends Store<ILoginStore> {
  private _currentNutrient = 'p';
  private _stores: Store<any>[] = [
    this.workTypeStore,
    this.fertilizerInorganicStore,
    this.fertilizerOrganicStore,
    this.cropGroupsStore,
    this.cropTypesStore,
    this.machineStore,
    this.instrumentStore,
    this.subFarmStore,
    this.machinesLoggerStore,
    this.loggerStore,
    this.driverStore,
    this.driverMachineStore,
    this.userStore,
    this.settingsStore,
    this.farmUsersStore,
    this.farmStore,
    this.accessPermissionsStore,
    this.menuStore,
    this.userGroupsUserStore,
    this.userGroupsStore,
    this.userSettingsStore,
    this.machineBreakStore,
    this.translationStore,
    this.landUseStore,
    this.licenseStore,
    this.nuptakeFactorsStore
  ];

  public BeforeNavigateToLogin = new EventHandler<string[]>();
  public BaseDataLoaded = new EventHandler();
  public baseDataLoaded$ = combineLatest([
    ...this._stores.map(st => st.Listen(s => s.loaded)),
  ]).pipe(
    filter(d => d.TrueForAll(state => !!state))
  );
  public baseDataLoadedProgress$ = combineLatest([
    ...this._stores.map(st => st.Listen(s => s.loaded)),
  ]).pipe(
    map(stores => {
      if (stores?.length <= 0) {
        return 0;
      }
      return stores?.FindAll(loaded => loaded === true)?.length / stores?.length * 100;
    })
  );

  constructor(public backend: ApSignalrService,
              private cookieService: ApCookieService,
              private roundService: GetRoundNumericService,
              private campaignYearService: CampaignYearService,
              private agriportConstantsService: AgriportConstantsService,
              private modalDialogPresets: ModalDialogPresets,
              private modalDialogStore: ModalDialogStore,
              private routerStore: RouterStore,
              private fieldStore: FieldStore,
              private permissionsStore: AccessPermissionsStore,
              private farmUsersStore: FarmUsersStore,
              private notifyStore: NotifyStore,
              private fertilizerInorganicStore: FertilizerInorganicStore,
              private fertilizerOrganicStore: FertilizerOrganicStore,
              private cropGroupsStore: CropGroupStore,
              private cropTypesStore: CropTypeStore,
              private machineStore: MachineStore,
              private instrumentStore: InstrumentStore,
              private subFarmStore: SubFarmStore,
              private machinesLoggerStore: MachineLoggerStore,
              private loggerStore: LoggerStore,
              private driverStore: DriverStore,
              private driverMachineStore: DriverMachineStore,
              private workTypeStore: WorkTypesStore,
              private userStore: UserStore,
              private campaignYearStore: CampaignYearStore,
              private settingsStore: SettingsStore,
              private accessPermissionsStore: AccessPermissionsStore,
              private menuStore: MenuStore,
              private userGroupsUserStore: UserGroupsUserStore,
              private userGroupsStore: UserGroupStore,
              private userSettingsStore: UserSettingsStore,
              private parameterDefinitionStore: ParameterDefinitionStore,
              private notificationStore: NotificationStore,
              private mapStore: MapStore,
              private formStore: FormStore,
              private krigingStore: KrigingStore,
              private applModesStore: ApplModesStore,
              private elementsStore: ElementsStore,
              private exportFormatsStore: ExportFormatsStore,
              private exportTargetsStore: ExportTargetsStore,
              private factorsStoreStore: FactorsStore,
              private operationModesStore: OperationModesStore,
              private unitsStore: UnitsStore,
              private machineBreakStore: MachineBreaksStore,
              private cropRotationStore: CropRotationStore,
              private soilSampleFieldStore: SoilSampleFieldStore,
              private jobStore: JobsStore,
              private translationStore: TranslationStore,
              private landUseStore: LandUseStore,
              private fieldNutrientDistributionStore: FieldNutrientDistributionStore,
              private basicFertilisationSummariesStore: BasicFertilisationSummariesStore,
              private farmStore: FarmStore,
              private licenseStore: LicenseStore,
              private nuptakeFactorsStore: NUptakeFactorsStore,
              private nuptakeStore: NUptakeStore,
              private languageStore: LanguageStore,
              private effectiveRangesStore: EffectiveRangesStore,
              private workTypeUnitStore: WorktypeUnitStore,
              private soilSampleDateStore: SoilSampleDateStore,
              private soilSampleDatesService: SoilSampleDateService) {
    super(backend, {
      login: {
        data: [],
        loading: false,
        loaded: false,
        user: null,
        username: null,
        password: null,
        emailAddress: null,
        isLoggedIn: false,
        message: '',
        isEmailAddressSend: false,
        isSetPassword: false,
        loginTimestamp: null,
        isStaySignedIn: false,
        loadingProgress: 0,
        maxProgress: 100,
        showProgressBar: false,
        delay: 0
      },
      farm: {
        data: [],
        loaded: false,
        loading: false,
        selectedFarm: null,
      },
      activeUsers: {
        data: [],
        loaded: false,
        loading: false
      },
      dataLoading: false,
      longOperationInProgress: false,
      clientErrorOccurred: false,
      isDebugModeEnabled: false,
      userPermissions: null
    });

    this.campaignYearStore.onLoadingStoresComplete.subscribe(() => {
      this.Mutate(s => s.dataLoading, () => false);
    });

    this.cropRotationStore.loginStoreFinish.emit(this);

    this.campaignYearStore.onSelectCampaignYear.subscribe(() => {
      if (this.routerStore.CurrentUrl !== '/login') {
        if (document.activeElement && typeof document.activeElement['blur'] === 'function') {
          document.activeElement['blur']();
        }
        this.Mutate(s => s.dataLoading, () => true);
      }
    });

    this.listenDatabaseUpdate();
    this.listenPermissions();
    this.listenCampaignYearSelect();
    this.listenFarmChange();
    this.listenFarmDataChange();
    this.listenSelectedFarmChange();
    this.listenBaseDataLoaded();
    this.listenLoginFail();
    this.listenLoginSuccess();
    this.listenLogout();
    this.listenSetCookie();
    this.listenLoginDelay();
    this.listenForCurrentUserFullNameUpdate();
    this.listenForResetRedirectToNews();
    this.nuptakeStore.listen();

    this.SelectedFarm$.subscribe(() => {
      this.campaignYearStore.Mutate(s => s.farmWasChanged, () => true);
    });

    this.campaignYearStore.SelectedCampaignYear$
      .pipe(filter(y => !!y && y.Year > 0))
      .subscribe(() => {
        this.soilSampleFieldStore.loadSoilSampleFields();
      });

    combineLatest([
      this.SelectedFarm$,
      this.campaignYearStore.SelectedCampaignYear$,
      this.routerStore.Listen(s => s.url),
    ]).subscribe(d => {
      if (!d || !d[0] || !d[1] || !d[2]) {
        return;
      }
      const farmId = d[0].Id;
      const year = d[1].Year;
      const url = d[2];
      this.mapStore.Layers.FieldDistributionLegendNutrientChange.emit('p');
      this.soilGroupLayerSourceInit(url, farmId, year);
      this.initialNdiLayerSource(url, farmId, year);
      this.initialNUptakeLayerSource(url, farmId, year);
    });

    this.mapStore.Layers.FieldDistributionLegendNutrientChange.subscribe(nutrient => {
      const farm = this.SelectedFarm$.getValue();
      const year = this.campaignYearStore.SelectedCampaignYear$.getValue();
      const currentUrl = this.routerStore.Listen(s => s.url).getValue();
      this._currentNutrient = nutrient;
      this.nutrientDistributionLayerSourceInit(currentUrl, farm.Id, year.Year, nutrient);
      this.soilSampleDistributionLayerSourceInit(currentUrl, farm.Id, year.Year, nutrient);
    });

    this.mapStore.Layers.NutrientPlaningLegendChange.subscribe(event => {
        if (event === null) {
          return;
        }
        const nutrientPlanningUrl = this.mapStore.Layers.NutrientPlanningUrl;
        const split = nutrientPlanningUrl.split('?');
        const provider = `pro_nutrient_planning_${event.selectionKeys[0].ToLower()}`;
        const map = `map_nutrient_planning_${event.selectionKeys[0].ToLower()}`;
        let url = '';
        if (!StringFactory.IsNullOrEmpty(provider) && !StringFactory.IsNullOrEmpty(map)) {
          url = `/map/${provider}/${map}/{z}/{x}/{y}?${split[1]}`;
        }
        this.mapStore.Layers.ChangeMapFactoryLayerUrl('', '', 0, 0, (store, sourceUrl) => store.Mutate(s => s.layer.nutrientPlanningUrl, () => url));
      }
    );

    this.mapStore.Layers.NeedLegendChange.subscribe(event => {
      const farm = this.SelectedFarm$.getValue();
      const year = this.campaignYearStore.SelectedCampaignYear$.getValue();
      const currentUrl = this.routerStore.Listen(s => s.url).getValue();
      if (event === null) {
        this.initialNeedLayerSourceInit(currentUrl, farm.Id, year.Year, 'p');
      } else {
        switch (event.selectionKeys[0]) {
          case '1' :
            this.initialNeedLayerSourceInit(currentUrl, farm.Id, year.Year, event.selectionKeys[1]);
            break;
          case '2' :
            // Restbedarf 1
            this.needLeftLayerSourceInit(currentUrl, farm.Id, year.Year, false, event.selectionKeys[1]);
            break;
          case '3' :
            // Restbedarf 2
            this.needLeftLayerSourceInit(currentUrl, farm.Id, year.Year, true, event.selectionKeys[1]);
            break;
        }
      }
    });

    this.krigingStore.OnInterpolationFinish.subscribe(() => {
      this.mapStore.Layers.ChangeMapFactoryLayerUrl('', '', 0, 0, (store, url) => store.Mutate(s => s.layer.distributionUrl, () => url));
      const farm = this.SelectedFarm$.getValue();
      const year = this.campaignYearStore.SelectedCampaignYear$.getValue();
      const currentUrl = this.routerStore.Listen(s => s.url).getValue();
      if (year) {
        this.nutrientDistributionLayerSourceInit(currentUrl, farm.Id, year.Year, this._currentNutrient);
        this.soilSampleDistributionLayerSourceInit(currentUrl, farm.Id, year.Year, this._currentNutrient);
        this.soilGroupLayerSourceInit(currentUrl, farm.Id, year.Year);
        this.initialNeedLayerSourceInit(currentUrl, farm.Id, year.Year, this._currentNutrient);
      }
    });
  }

  public get FieldStore(): FieldStore {
    return this.fieldStore;
  }

  public get CampaignYearStore(): CampaignYearStore {
    return this.campaignYearStore;
  }

  public get UserPermissions$(): SafeBehaviorSubject<IApPermissionData> {
    return this.Listen(s => s.userPermissions);
  }

  public get SelectedFarm$(): SafeBehaviorSubject<IFarm> {
    return this.Listen(s => s.farm.selectedFarm);
  }

  public get Farms$(): SafeBehaviorSubject<IFarm[]> {
    return this.Listen(s => s.farm.data);
  }

  public get IsLoggedIn$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.login.isLoggedIn);
  }

  public get Message$(): SafeBehaviorSubject<string> {
    return this.Listen(s => s.login.message);
  }

  public get LoginLoading$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.login.loading);
  }

  public get IsStaySignedIn$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.login.isStaySignedIn);
  }

  public get LoadingProgress$(): SafeBehaviorSubject<number> {
    return this.Listen(s => s.login.loadingProgress);
  }

  public get MaxProgress$(): SafeBehaviorSubject<number> {
    return this.Listen(s => s.login.maxProgress);
  }

  public get ShowProgressBar$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.login.showProgressBar);
  }

  public get Delay$(): SafeBehaviorSubject<number> {
    return this.Listen(s => s.login.delay);
  }

  public get EmailAddress$(): SafeBehaviorSubject<string> {
    return this.Listen(s => s.login.emailAddress);
  }

  public get IsEmailAddressSend$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.login.isEmailAddressSend);
  }

  public get User$(): SafeBehaviorSubject<IUser> {
    return this.Listen(s => s.login.user);
  }

  public get Farms(): IFarm[] {
    return this.Farms$.getValue();
  }

  public get SelectedFarm(): IFarm {
    return this.SelectedFarm$.getValue();
  }

  public get SelectedFarmId(): number {
    return this.SelectedFarm$.getValue().Id;
  }

  public get User(): IUser {
    return this.Listen(s => s.login.user).getValue();
  }

  public get UserId(): number {
    return this.Listen(s => s.login.user).getValue().Id;
  }

  public setLongOperationStatus(status: boolean): void {
    this.Mutate(s => s.longOperationInProgress, _ => status);
  }

  public setClientErrorOccurred(status: boolean): void {
    this.Mutate(s => s.clientErrorOccurred, _ => status);
  }

  /**
   * try to change the selected farm
   */
  public changeSelectedFarm(farm: IFarm): void {
    if (this.formStore.tryCloseForm()) {
      const subscription = this.formStore.formCloseOnRequest.subscribe((close) => {
        if (close) {
          this.DispatchBackend(new InformFarmChange([
            {Name: 'farmId', Type: NetTypes.INT, Value: farm.Id},
          ]));
        }
        subscription.unsubscribe();
      });
    } else if (this.routerStore.tryNavigate()) {
      const subscription = this.routerStore.navigateRequest.subscribe((close) => {
        if (close) {
          this.DispatchBackend(new InformFarmChange([
            {Name: 'farmId', Type: NetTypes.INT, Value: farm.Id},
          ]));
        }
        subscription.unsubscribe();
      });
    } else {
      this.DispatchBackend(new InformFarmChange([
        {Name: 'farmId', Type: NetTypes.INT, Value: farm.Id},
      ]));
    }
  }

  /**
   * try to the user stay sign in
   */
  public loginUserStaySignedIn(value: boolean): void {
    this.Mutate(s => s.login.isStaySignedIn, () => value);
  }

  /**
   * try to login the given User
   */
  public loginUser(email: string, password: string): void {
    this.updateProgressBarVisibility(true);
    this.Mutate(s => s.login.message, () => null);
    this.Mutate(s => s.login.delay, () => 0);
    this.DispatchBackend(new UserLogin([
      {
        Name: 'data',
        Type: ApCustomTypes.Data_Authentication_Login,
        Value: {
          Email: email,
          Password: ApBase64Util.stringToBase64(password),
          LoginTime: new Date().valueOf(),
          SessionDuration: this.getSessionDuration()
        } as ILogin
      }
    ]));
  }

  /**
   * Some actions when user leaves agriport by closing window/tab
   * while being logged in.
   */
  public pageLeave(): void {
    this.DispatchBackend(new UserLeavePage());
  }

  /**
   * try to logout
   */
  public logoutUser(): void {
    this.cookieService.logout();

    // Do not clear translation store on logout
    // This store does not need a reload
    for (const store of this._stores.FindAll(_ => _ !== this.translationStore)) {
      store.Clear();
    }
    this.mapStore.Layers.Clear();
    ApMapInstance.clear();
    this.Mutate(s => s.login, () =>
      ({
        data: [],
        loading: false,
        loaded: false,
        user: null,
        username: null,
        password: null,
        emailAddress: null,
        isLoggedIn: false,
        message: '',
        isEmailAddressSend: false,
        isSetPassword: false,
        loginTimestamp: null,
        isStaySignedIn: false,
        loadingProgress: 0,
        maxProgress: 100,
        showProgressBar: false,
        delay: 0
      } as ILoginState));

    super.Mutate(s => s.farm, () =>
      ({
        data: [],
        loaded: false,
        loading: false,
        selectedFarm: null
      } as IFarmState));

    this.licenseStore.setLicenseEmpty();

    this.routerStore.navigateToLogin().then(() => {
    });
  }

  public updateLoadingProgress(value: number): void {
    this.Mutate(s => s.login.loadingProgress, () => value);
  }

  public updateProgressBarVisibility(value: boolean): void {
    this.Mutate(s => s.login.showProgressBar, () => value);
  }

  public loginWithCookie(): void {
    if (this.cookieService.getCookie()) {
      this.updateProgressBarVisibility(true);
    }
    this.cookieService.login();
  }

  /**
   * Turns on/off debug mode of Agriport.
   * This feature enables additional output in console or showing all columns
   * in dataGrids.
   * It can be activated by Agricon-Admins with pressing Ctrl-Alt-Space.
   */
  public switchDebugMode(): void {
    if (!ApRoleTypeService.hasAgriconRole(this.User)) {
      return;
    }
    const isDebugModeEnabled = !this.Listen(s => s.isDebugModeEnabled).getValue();
    // ApMapInstance is no component but a static class
    // Therefor we have to enable/disable the debug output like this
    // MapDebug is only enabled for developer level
    if (ApRoleTypeService.hasDeveloperRole(this.User)) {
      ApMapInstance.isDebugModeEnabled = isDebugModeEnabled;
      ApSignalrService.isDebugMode = isDebugModeEnabled;
    }
    super.Mutate(s => s.isDebugModeEnabled, () => isDebugModeEnabled);
  }

  private nutrientDistributionLayerSourceInit(url: string, farmId: number, year: number, nutrient: string): void {
    const provider = `pro_wsv_${nutrient.ToLower()}`;
    const map = `map_wsv_${nutrient.ToLower()}`;
    const sampleMap = `map_samples_${nutrient.ToLower()}`;
    this.mapStore.Layers.ChangeMapFactoryLayerUrl(provider, map, farmId, year, (store, sourceUrl) => store.Mutate(s => s.layer.distributionUrl, () => sourceUrl));
    this.mapStore.Layers.ChangeMapFactoryLayerUrl('pro_samples', sampleMap, farmId, year, (store, sourceUrl) => store.Mutate(s => s.layer.soilSampleUrl, () => sourceUrl));
  }

  private soilSampleDistributionLayerSourceInit(url: string, farmId: number, year: number, nutrient: string): void {
    const provider = `pro_bu_${nutrient.ToLower()}`;
    const map = `map_bu_${nutrient.ToLower()}`;
    const sampleMap = `map_samples_${nutrient.ToLower()}`;
    this.mapStore.Layers.ChangeMapFactoryLayerUrl(provider, map, farmId, year, (store, sourceUrl) => store.Mutate(s => s.layer.soilSampleDistributionUrl, () => sourceUrl));
  }

  private soilGroupLayerSourceInit(url: string, farmId: number, year: number): void {
    let layerProvider = 'pro_wsv_soilgroup';
    let layerMap = 'map_wsv_soilgroup';
    if (url.Split('?').FirstOrDefault()?.EndsWith('/soil-sample-fields')) {
      layerProvider = 'pro_bu_soilgroup';
      layerMap = 'map_bu_soilgroup';
    }
    this.mapStore.Layers.ChangeMapFactoryLayerUrl(layerProvider, layerMap, farmId, year, (store, sourceUrl) => store.Mutate(s => s.layer.soilGroupUrl, () => sourceUrl));
  }

  private needLeftLayerSourceInit(url: string, farmId: number, year: number, isNeedLeftPlanned: boolean, nutrient: string): void {
    let provider = isNeedLeftPlanned === true ? 'pro_rb_planned_' : 'pro_rb_';
    let map = isNeedLeftPlanned === true ? 'map_rb_planned_' : 'map_rb_';
    provider = `${provider}${nutrient.ToLower()}`;
    map = `${map}${nutrient.ToLower()}`;

    this.fieldStore.Fields$.subscribe(fields => {
      if (fields == null) {
        return;
      }
      if (fields.length === 0) {
        return;
      }
      const tmp = [];
      for (const field of fields) {
        if (!field.FieldGeoms || typeof field.FieldGeoms !== typeof [] || field.FieldGeoms.length < 1) {
          continue;
        }
        for (const geom of field.FieldGeoms) {
          tmp.push(geom.Id);
        }
      }
      this.mapStore.Layers.NeedLeftLayerSourceInit(provider, map, tmp);
    });
  }

  private initialNeedLayerSourceInit(url: string, farmId: number, year: number, nutrient: string): void {
    const provider = `pro_ir_contours_${nutrient.ToLower()}`;
    const map = `map_ir_contours_${nutrient.ToLower()}`;
    this.mapStore.Layers.ChangeMapFactoryLayerUrl(provider, map, farmId, year, (store, sourceUrl) => store.Mutate(s => s.layer.rbUrl, () => sourceUrl));
  }

  private initialNdiLayerSource(url: string, farmId: number, year: number): void {
    this.mapStore.Layers.ChangeMapFactoryLayerUrlNdi('pro_ndi', 'map_ndi', farmId, year, (store, sourceUrl) => store.Mutate(s => s.layer.ndiLayerUrl, () => sourceUrl));
  }

  private initialNUptakeLayerSource(url: string, farmId: number, year: number): void {
    this.mapStore.Layers.ChangeMapFactoryLayerUrlNUptake('pro_nuptake', 'map_nuptake', farmId, year, (store, sourceUrl) => store.Mutate(s => s.layer.nUptakeUrl, () => sourceUrl));
  }

  /**
   * Calculates the session's duration depending on user choice 'stay signed in'q
   */
  private getSessionDuration(): number {
    return this.Listen(s => s.login.isStaySignedIn).getValue() ? AgriportSessionDuration.ExtendedSessionAgeHours : AgriportSessionDuration.DefaultSessionAgeHours;
  }

  private listenDatabaseUpdate(): void {
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  private listenPermissions(): void {
    combineLatest([
      this.farmUsersStore.Listen(s => s.data),
      this.Listen(s => s.login.user),
      this.permissionsStore.Listen(s => s.data),
      this.Listen(s => s.farm.selectedFarm),
    ]).subscribe(d => {
      this.Mutate(s => s.userPermissions, () => ({
        FarmUsers: d[0],
        User: d[1],
        Permissions: d[2],
        Farm: d[3]
      }));
    });
  }

  /**
   * when farm was changed in the Backend Session set the selected farm with the farm from the farm list find by id
   */
  private listenFarmChange(): void {
    this.backend.registerObservable(InformFarmChangeSuccess).subscribe(d => {
      if (d === null) {
        return;
      }
      this.mapStore.Layers.Reset();
      const selectedFarm = this.Farms.Find((f: IFarm) => f.Id === d.Data);
      this.Mutate(s => s.farm.selectedFarm, () => selectedFarm);
    });
  }

  /**
   * on farm data is load get the last selected farm from local cache and select the Farm when it was in the list
   * otherwise select the first farm in the farm list
   */
  private listenFarmDataChange(): void {
    this.Listen(s => s.farm.data).subscribe(async (d) => {
      if (d.length === 0) {
        return;
      }
      const farmId = ClientCache.readValue<number>(APP_CONFIGURATION.StoreKeys.LastVisitFarmId);
      const matchFarm = (d.Find((e: IFarm) => e.Id === farmId) || d[0]);
      if (matchFarm !== undefined) {
        this.changeSelectedFarm(matchFarm);
      }
    });
  }

  /**
   * Loads all stores which contain non farm related data.
   * This load process should be triggered only once after login
   * @private
   */
  private loadFarmIndependentBaseData(): void {
    this.fieldNutrientDistributionStore.loadSampleMethods();
    this.fieldNutrientDistributionStore.loadSampleRegions();
    this.landUseStore.loadLandUse();
    this.factorsStoreStore.loadFactors();
    this.nuptakeFactorsStore.loadNUptakeFactors();
    this.unitsStore.loadUnits();
    this.operationModesStore.loadOperationModes();
    this.exportFormatsStore.loadExportFormats();
    this.exportTargetsStore.loadExportTargets();
    this.applModesStore.loadApplModes();
    this.elementsStore.loadElements();
    this.workTypeStore.loadWorktypes();
    this.effectiveRangesStore.loadEffectiveRanges();
    this.workTypeUnitStore.loadWorktypeUnits();
  }

  /**
   * when a farm was selected load all farm specific data
   */
  private listenSelectedFarmChange(): void {
    combineLatest([this.Listen(s => s.farm.selectedFarm),
      this.translationStore.Listen(s => s.loading)]).pipe(
      filter(([selectedFarm, translationsLoading]) => selectedFarm && !translationsLoading))
      .subscribe(async ([selectedFarm]) => {
        ClientCache.writeValue(APP_CONFIGURATION.StoreKeys.LastVisitFarmId, selectedFarm.Id);
        if (this.routerStore.CurrentUrl !== '/login') {
          if (document.activeElement && typeof document.activeElement['blur'] === 'function') {
            document.activeElement['blur']();
          }
          this.Mutate(s => s.dataLoading, () => true);
        }

        this._showLicenseAdjustmentModalWindow();

        this.campaignYearStore.loadCampaignYears();
        this.licenseStore.loadLicensesForFarm();
        this.userStore.loadUsers();
        this.settingsStore.loadSettings();
        this.settingsStore.loadTimeZones();
        this.farmUsersStore.loadFarmUsers();
        this.accessPermissionsStore.loadAccessPermissions();
        this.menuStore.fillMenuData();
        this.userGroupsUserStore.loadUserGroupsUser();
        this.userGroupsStore.loadUserGroups();
        this.userSettingsStore.loadUserSettings();
        this.farmStore.loadFarm();
        this.fertilizerInorganicStore.loadFertilizerInorganic();
        this.fertilizerOrganicStore.loadFertilizerOrganic();
        this.cropGroupsStore.loadCropGroups();
        this.cropTypesStore.loadCroptypes();
        this.machineStore.loadMachines();
        this.machineStore.loadMachineTypes();
        this.instrumentStore.loadInstruments();
        this.subFarmStore.loadSubFarms();
        this.machinesLoggerStore.loadMachinesLogger();
        this.loggerStore.loadLogger();
        this.driverStore.loadDrivers();
        this.driverMachineStore.loadDriverMachine();
        this.parameterDefinitionStore.loadParameterDefinition();
        this.notificationStore.loadNotification();
        this.machineBreakStore.loadMachineBreaks();
        this.basicFertilisationSummariesStore.loadBasicFertilisationSummaries();
      });
    combineLatest([
      this.fieldStore.Loaded$,
      this.soilSampleFieldStore.Loaded$,
    ]).pipe(
      filter(args => args.TrueForAll(arg => arg))
    ).subscribe(() => {
      if (!this.SelectedFarm || this.SelectedFarm?.Id < 0) {
        return;
      }
      this.jobStore.loadFarmJobs(this.SelectedFarm?.Id, 30);
    });
  }

  private listenBaseDataLoaded(): void {
    this.baseDataLoadedProgress$.subscribe(baseDataLoadedPercentage => {
      this.Mutate(s => s.login.loadingProgress, () => baseDataLoadedPercentage);
    });

    this.baseDataLoaded$.subscribe(async () => {
      this.BaseDataLoaded.Invoke(null);
      const lastVisitCampaignYearId = ClientCache.readValue<number>(APP_CONFIGURATION.StoreKeys.LastVisitCampaignYear);
      let year = this.campaignYearStore.getCampaignYears().Find(c => c.Id === lastVisitCampaignYearId);
      if (!year) {
        year = this.campaignYearStore.getCampaignYears()[0];
      }
      if (!this.campaignYearStore.FarmWasChanged && this.campaignYearStore.SelectedCampaignYear?.Year === year.Year) {
        return;
      }
      this.campaignYearStore.Mutate(s => s.farmWasChanged, () => false);
      this.campaignYearStore.selectCampaignYear(year);
    });
  }

  private listenLoginFail(): void {
    this.backend.registerObservable(UserLoginFail).subscribe(d => {
      this.Mutate(s => s.login.loading, () => false);
      this.Mutate(s => s.login.data, () => []);
      this.Mutate(s => s.login.user, () => null);
      this.Mutate(s => s.login.message, () => d.Data);
      this.Mutate(s => s.login.isLoggedIn, () => false);
    });
  }

  private listenLoginSuccess(): void {
    this.backend.registerObservable(UserLoginSuccess).subscribe(d => {
      this.loadFarmIndependentBaseData();
      this.DispatchBackend(new MapFactoryAuthenticationLoad()).watchStream({
        action: MapFactoryAuthenticationSuccess,
        todo: payload => {
          const keys = Object.keys(payload.Data);
          if (keys.length < 1) {
            console.warn(`invalid map factory authentication`);
            return;
          }
          MapFactoryAuthenticator.userInfo = {
            user: keys[0], password: payload.Data[keys[0]],
          };
          MapFactoryAuthenticator.getHash().then(() => {
            this.Mutate((s) => s.login.data, () => d.Data);
            this.Mutate((s) => s.login.user, () => d.Data[0].User);
            this.Mutate((s) => s.login.isLoggedIn, () => true);
            this.Mutate((s) => s.login.loginTimestamp, () => getNow().toJSON());
            this.Mutate((s) => s.farm.data, () => d.Data.Convert(e => e.Farm));

            // init map factory style needs a logged in user
            mapFactoryStyler().reloadServerStyles().then();
          }).catch(err => {
            console.warn('error on authenticate on map factory', err);
          });
        }
      });
      this.languageStore.saveSelectedLanguageToLocalStorage();
    });
  }

  private listenCampaignYearSelect(): void {
    this.backend.registerObservable(CampaignYearSelect).subscribe(async () => {
      this.Mutate(s => s.login.loadingProgress, () => 100);
      const res = ClientCache.readValue<string>(APP_CONFIGURATION.StoreKeys.LastVisitUrl);
      const params = ClientCache.readValue<any>(APP_CONFIGURATION.StoreKeys.LastVisitUrlParams);
      let url = [res || APP_CONFIGURATION.StartPage];
      let extras = params || {};
      if (this.User?.RedirectToNews) {
        url = ['/dashboard'];
        extras = {redirectToNews: true};
      }

      this.BeforeNavigateToLogin.Invoke(url);
      await this.routerStore.navigate(url, extras);
    });
  }

  private listenLogout(): void {
    this.backend.registerObservable(LogoutFromSystem).subscribe(() => {
      const message = {
        DisplayTimeout: 10,
        Level: Data.Api.Validation.ApValidationLevel.Info,
        ErrorKey: 'DataContext__SessionExpired'
      } as IApValidationResult;
      this.notifyStore.addMessage(message);
      this.logoutUser();
    });
  }

  private listenForCurrentUserFullNameUpdate(): void {
    this.backend.registerObservable(UserUpdateFullNameSuccess).subscribe(d => {
      if (!d?.Data && d.Data.Id !== this.UserId) {
        return;
      }
      this.Mutate(x => x.login, () => {
        const loginState = ObjectFactory.Copy(this.Listen(x => x.login).getValue());
        loginState.user = d.Data;
        return loginState;
      });
    });
  }

  private listenForResetRedirectToNews(): void {
    this.backend.registerObservable(UserResetRedirectToNewsSuccess).subscribe(d => {
      if (!d?.Data && d.Data.Id !== this.UserId) {
        return;
      }
      this.Mutate(x => x.login, () => {
        const loginState = ObjectFactory.Copy(this.Listen(x => x.login).getValue());
        loginState.user = d.Data;
        return loginState;
      });
    });
  }

  private listenSetCookie(): void {
    this.cookieService.getSessionDuration = () => this.getSessionDuration();
    this.cookieService.afterSetCookie = () => {
      this.updateProgressBarVisibility(true);
    };
  }

  private listenLoginDelay(): void {
    this.backend.registerObservable(UserLoginDelay).subscribe(d => {
      this.Mutate(s => s.login.delay, () => d.Data);
    });
  }

  private _showLicenseAdjustmentModalWindow(): void {
    // Here we show modal window about license information with an ability to extend it.
    // It shows in case when the amount of area for current campaign year is > or < 10 % from the licensed base fertilisation modul feature area
    let isModalDialogShowed = false;
    const licenseCheckSubscription = combineLatest([
      this.baseDataLoaded$,
      this.licenseStore.licenseData$,
      this.fieldStore.Fields$,
      this.campaignYearStore.SelectedCampaignYear$,
      this.soilSampleDateStore.Dates$,
      this.SelectedFarm$
    ]).pipe(
      filter(([loadedData, licenses, fields, selectedCampaignYear, _, selectedFarm]) => {
        if (loadedData.some(x => !x) || !selectedFarm || !selectedCampaignYear || !licenses || !fields) {
          return false;
        }
        const campaignYearRange = this.campaignYearService.getCampaignYearByDate(new Date());
        const selectedYearRange = this.campaignYearService.getCampaignYearRange(selectedCampaignYear.Year);
        return campaignYearRange.StartYear < campaignYearRange.EndYear
          && selectedYearRange.StartYear < campaignYearRange.EndYear
          && this.campaignYearService.isCampaignYearIntersects(campaignYearRange.StartYear, campaignYearRange.EndYear, selectedYearRange);
      }),
      map(([, licenses, fields, selectedCampaignYear, soilSampleDates, selectedFarm]) => {
        const fieldsWithValidSoilSample = this.soilSampleDatesService.getFieldsWithValidSoilSampleDate(fields, soilSampleDates)
          .filter(x => x.FarmId === selectedFarm.Id);
        const selectedYearRange = this.campaignYearService.getCampaignYearRange(selectedCampaignYear.Year);
        const license = licenses.find(x => x.FeatureModul.Key === ApFeatureModulKey.BaseFertilization
          && x.FarmId === selectedFarm.Id
          && this.campaignYearService.isCampaignYearIntersects(new Date(x.ValidFrom), new Date(x.ValidTo), selectedYearRange));
        return ([license, fieldsWithValidSoilSample, selectedCampaignYear] as [ILicense, IField[], ICampaignYear]);
      }),
      filter(([license, fields, selectedCampaignYear]) => {
        return fields.length > 0
          && license && license.QuantityErp && !license.NoCheck
          && selectedCampaignYear.DefaultStart < selectedCampaignYear.DefaultEnd;
      })
    ).subscribe(([license, fields, selectedCampaignYear]) => {
      const fieldsAreaHaSum = fields.map(x => this.fieldStore.getFieldGeom(x, selectedCampaignYear))
        .reduce((total, current) => total + Create(current?.AreaHa, 0), 0);
      let quantityForConditionActivation = 0;
      const licenseComparisonType = this.agriportConstantsService.GetConstant(AgriportConstantsEnum.BaseFertLicenseAmountAbs);
      const licenseComparisonAmount = +this.agriportConstantsService.GetConstant(AgriportConstantsEnum.BaseFertLicenseAmountDiff);
      if (!isFinite(licenseComparisonAmount) || licenseComparisonAmount <= 0) {
        licenseCheckSubscription?.unsubscribe();
        isModalDialogShowed = false;
        return;
      }
      switch (licenseComparisonType) {
        case BaseFertLicenseAmountAbs.Hectare:
          quantityForConditionActivation = licenseComparisonAmount;
          break;
        case BaseFertLicenseAmountAbs.Percent:
          quantityForConditionActivation = fieldsAreaHaSum * licenseComparisonAmount / 100;
          break;
      }
      let modalWindowMessage = '';
      switch (true) {
        case fieldsAreaHaSum >= license.QuantityErp + quantityForConditionActivation:
          modalWindowMessage = this.translationStore.FindTranslationForSelectedLanguage('InfoText__Base_Fert_Feature_Area_Lower');
          break;
        default:
          licenseCheckSubscription?.unsubscribe();
          isModalDialogShowed = false;
          return;
      }
      const farmQuantityTranslation = this.translationStore.FindTranslationForSelectedLanguage('InfoText__Farm_Area_Quantity');
      const licenseQuantityTranslation = this.translationStore.FindTranslationForSelectedLanguage('InfoText__Licensed_Area_Quantity');
      modalWindowMessage += `\r\n\r\n${farmQuantityTranslation}: ${this.roundService.round(fieldsAreaHaSum)} ha` +
        `\r\n${licenseQuantityTranslation}: ${license.QuantityErp} ha`;
      if (!isModalDialogShowed) {
        isModalDialogShowed = true;
        this.modalDialogStore.setModalDialogData(this.modalDialogPresets.ExtendLicenseDialog({
          message: modalWindowMessage,
          resultDelegate: (dialogResult: IModalDialogResult): void => {
            let adjustAction = LicenseAdjustAction.Skip;
            if (dialogResult?.key === ModalDialogButtonKeys.Confirm) {
              adjustAction = LicenseAdjustAction.Adjust;
            }
            this.licenseStore.adjustLicenseRequest(ApFeatureModulKey.BaseFertilization, adjustAction);
            licenseCheckSubscription?.unsubscribe();
            isModalDialogShowed = false;
          }
        }));
      }
    });
  }
}
