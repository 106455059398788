import {NgModule} from '@angular/core';
import {
  CommonModule
}                 from '@angular/common';
import {
  ApDynGridsComponent
}                 from './ap-dyngrids.component';
import {
  BodyModule,
  ExcelModule,
  FilterMenuModule,
  GridModule,
  GroupModule,
  HeaderModule,
  PagerModule,
  PDFModule
}                 from '@progress/kendo-angular-grid';
import {
  ApCoreModule
}                 from '../ap-core/ap-core.module';
import {
  ApTranslationModule
}                 from '../translation/ap-translation.module';
import {
  ApUtilsModule
}                 from '../ap-utils';
import {
  ButtonsModule
}                 from '@progress/kendo-angular-buttons';
import {
  ApTooltipModule
}                 from '../ap-tooltip/ap-tooltip.module';
import {
  ApResponsiveTextModule
}                 from '../ap-responsive-text/ap-responsive-text.module';
import {
  ApSectionHeaderModule
}                 from '../ap-section-header/ap-section-header.module';
import {
  ApStatusSwitchModule
}                 from '../ap-status-switch/ap-status-switch.module';
import {
  AsClassColorPipe
}                 from './pipes/convert/as-class-color.pipe';
import {
  AsButtonPipe
}                 from './pipes/convert/as-button.pipe';
import {
  AsEditPipe
}                 from './pipes/convert/as-edit.pipe';
import {
  AsGroupPipe
}                 from './pipes/convert/as-group.pipe';
import {
  AsPagerPipe
}                 from './pipes/convert/as-pager.pipe';
import {
  AsPropertyPipe
}                 from './pipes/convert/as-property.pipe';
import {
  AsStatusPipe
}                 from './pipes/convert/as-status.pipe';
import {
  AsButtonGroupPipe
}                 from './pipes/convert/as-button-group.pipe';
import {
  AsColorPipe
}                 from './pipes/convert/as-color.pipe';
import {
  AsImagePipe
}                 from './pipes/convert/as-image.pipe';
import {
  AsStatusSwitchPipe
}                 from './pipes/convert/as-status-switch.pipe';
import {
  AsWizardPipe
}                 from './pipes/convert/as-wizard.pipe';
import {
  AsWizardButtonPipe
}                 from './pipes/convert/as-wizard-button.pipe';
import {
  AsWizardExportPipe
}                 from './pipes/convert/as-wizard-export.pipe';
import {
  AsWizardBreadcrumpsStepperPipe
}                 from './pipes/convert/as-wizard-breadcrumps-stepper.pipe';
import {
  GetTextPipe
}                 from './pipes/getter/get-text.pipe';
import {
  GetPropertyPipe
}                 from './pipes/getter/get-property.pipe';
import {
  AgriportStoreModule
}                 from '../stores/stores.module';
import {
  AsDetailsGridPipe
}                 from './pipes/convert/as-details-grid.pipe';
import {
  DropDownButtonData
}                 from './config/ap-dyn-grid-button-column-config';
import {
  CenterCellPipe
}                 from './pipes/center-cell.pipe';
import {
  ExecPipesPipe
}                 from './pipes/exec.pipes.pipe';
import {
  IsHiddenPipe
}                 from './pipes/is-hidden.pipe';
import {
  AsTemplatePipe
}                 from './pipes/convert/as-template.pipe';
import {
  GetTemplateContextPipe
}                 from './pipes/get-template-context.pipe';
import {
  ApPropertyColumnCellComponent,
  ApPropertyColumnFilterComponent,
  ApPropertyColumnHeaderComponent
}                 from './components/ap-property-column.component';
import {
  ApStatusColumnCellComponent,
  ApStatusColumnFilterComponent,
  ApStatusColumnGroupFilterComponent,
  ApStatusColumnHeaderComponent
}                 from './components/ap-status-column.component';
import {
  ApTemplateColumnCellComponent,
  ApTemplateColumnHeaderComponent
}                 from './components/ap-template-column.component';
import {
  ApStatusSwitchColumnCellComponent,
  ApStatusSwitchColumnFilterComponent,
  ApStatusSwitchColumnGroupHeaderComponent
}                 from './components/ap-status-switch-column.component';
import {
  ApColorColumnCellComponent
}                 from './components/ap-color-column.component';
import {
  ApImageColumnCellComponent
}                 from './components/ap-image-column.component';
import {
  ApClassColorColumnCellComponent,
  ApClassColorColumnFilterComponent
}                 from './components/ap-class-color-column.component';
import {
  ApButtonColumnCellComponent
}                 from './components/ap-button-column.component';
import {
  ApButtonGroupColumnCellComponent
}                 from './components/ap-button-group-column.component';
import {
  ApEditColumnCellComponent
}                 from './components/ap-edit-column.component';
import {
  IndicatorsModule
}                 from '@progress/kendo-angular-indicators';
import {
  IdInPipe
}                 from './pipes/id-in.pipe';
import {
  TranslateKendoStatePipe
}                 from './pipes/translate-kendo-state.pipe';
import {
  ApIconModule
}                 from '../icons/ap-icon.module';
import {
  ClipboardModule
}                 from '@angular/cdk/clipboard';
import {
  ContextMenuModule
}                 from '@progress/kendo-angular-menu';
import { DynamicCellPipe } from './pipes/dynamic-cell.pipe';
import {AsIconPipe} from './pipes/convert/as-icon.pipe';

@NgModule({
  declarations: [
    ApDynGridsComponent,
    AsClassColorPipe,
    AsButtonPipe,
    AsEditPipe,
    AsGroupPipe,
    AsPagerPipe,
    AsPropertyPipe,
    AsStatusPipe,
    AsButtonGroupPipe,
    AsColorPipe,
    AsImagePipe,
    AsStatusSwitchPipe,
    AsWizardPipe,
    AsWizardButtonPipe,
    AsWizardExportPipe,
    AsWizardBreadcrumpsStepperPipe,
    AsIconPipe,
    GetTextPipe,
    GetPropertyPipe,
    AsDetailsGridPipe,
    CenterCellPipe,
    ExecPipesPipe,
    IsHiddenPipe,
    AsTemplatePipe,
    GetTemplateContextPipe,
    ApPropertyColumnCellComponent,
    ApPropertyColumnFilterComponent,
    ApPropertyColumnHeaderComponent,
    ApStatusColumnCellComponent,
    ApStatusColumnFilterComponent,
    ApStatusColumnHeaderComponent,
    ApStatusColumnGroupFilterComponent,
    ApTemplateColumnCellComponent,
    ApTemplateColumnHeaderComponent,
    ApStatusSwitchColumnCellComponent,
    ApColorColumnCellComponent,
    ApImageColumnCellComponent,
    ApClassColorColumnCellComponent,
    ApClassColorColumnFilterComponent,
    ApButtonColumnCellComponent,
    ApButtonGroupColumnCellComponent,
    ApEditColumnCellComponent,
    ApStatusSwitchColumnFilterComponent,
    ApStatusSwitchColumnGroupHeaderComponent,
    IdInPipe,
    TranslateKendoStatePipe,
    DynamicCellPipe
  ],
  exports: [
    ApDynGridsComponent,
    ApStatusColumnCellComponent,
  ],
  imports: [
    CommonModule,
    GridModule,
    ApCoreModule,
    FilterMenuModule,
    HeaderModule,
    BodyModule,
    ApTranslationModule,
    ApUtilsModule,
    ButtonsModule,
    ExcelModule,
    PDFModule,
    PagerModule,
    GroupModule,
    ApTooltipModule,
    ApResponsiveTextModule,
    ApSectionHeaderModule,
    ApStatusSwitchModule,
    AgriportStoreModule,
    IndicatorsModule,
    ApIconModule,
    ClipboardModule,
    ContextMenuModule
  ],
  providers: [
    AsButtonPipe,
    AsPagerPipe,
    AsColorPipe,
    AsStatusSwitchPipe,
    AsWizardButtonPipe,
    AsWizardExportPipe,
    DropDownButtonData
  ]
})
export class ApDynGridsModule {
}
