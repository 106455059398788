/**
 * enum of all available control types.
 */
import {Observable, of} from 'rxjs';
import {PipeTransform}  from '@angular/core';
import {Guid}           from 'ts-tooling';
import IGuid = System.IGuid;

export class ApDynGridColumnHideConfig {
  mapHide: boolean;
  selfHide: Observable<boolean>;
  widthHidePriority: number;

  constructor(options: {
    mapHide?: boolean,
    selfHide?: Observable<boolean>,
    widthHidePriority?: number
  } = {}) {
    this.mapHide = options.mapHide === true;
    this.selfHide = options.selfHide ? options.selfHide : of(false);
    this.widthHidePriority = options.widthHidePriority;
  }
}

export enum ApDynGridColumnControlType {
  Status = 'Status',
  Property = 'Property',
  ColumnGroup = 'ColumnGroup',
  Edit = 'Edit',
  Button = 'Button',
  ButtonGroup = 'ButtonGroup',
  Color = 'Color',
  StatusSwitch = 'StatusSwitch',
  Image = 'Image',
  ClassColor = 'ClassColor',
  Template = 'Template',
  Icon = 'Icon'
}

export interface IPipeDefinition {
  pipe: PipeTransform;
  args: any[];
}

export class ApDynGridColumnConfigBase {
  public id: IGuid;
  type: ApDynGridColumnControlType;
  hide: ApDynGridColumnHideConfig;
  public field: string;
  sortIndex: number;
  sortDesc = false;
  groupIndex: number;
  groupDesc = false;
  headerStyle: any;
  style: any;
  minWidth: number;
  width: number;
  pdfExport: boolean;
  xlsExport: boolean;
  headerFilterable = false;
  enable = false;
  cssClass: string = null;
  dynCssClass: (dataItem: any) => string;
  pipes: IPipeDefinition[] = [];
  tooltipPipes: IPipeDefinition[] = [];
  defaultFilter: any;
  sortable = true;

  constructor(options: {
    hide?: boolean | ApDynGridColumnHideConfig,
    sortIndex?: number,
    sortDesc?: boolean,
    groupIndex?: number,
    groupDesc?: boolean,
    headerStyle?: any,
    style?: any,
    width?: number,
    pdfExport?: boolean,
    xlsExport?: boolean,
    enable?: boolean,
    cssClass?: 'left' | 'right' | 'center',
    dynCssClass?: (dataItem: any) => string,
    pipes?: IPipeDefinition[],
    tooltipPipes?: IPipeDefinition[],
    defaultFilter?: any,
    field?: string,
    sortable?: boolean
  } = {}) {
    this.id = new Guid();
    if (options.hide instanceof ApDynGridColumnHideConfig &&
      options.hide.widthHidePriority !== undefined &&
      options.width === undefined) {
      throw new Error('Width is required if widthHidePriority is set');
    }
    this.headerStyle = options.headerStyle ? options.headerStyle : {};
    if (options.hide instanceof ApDynGridColumnHideConfig) {
      this.hide = options.hide;
    } else {
      this.hide = new ApDynGridColumnHideConfig({mapHide: options.hide});
    }
    this.pdfExport = options.pdfExport ?? true;
    this.style = options.style || {};
    this.width = options.width;
    this.xlsExport = options.xlsExport ?? true;
    this.groupIndex = options.groupIndex;
    this.groupDesc = options.groupDesc || false;
    this.sortIndex = options.sortIndex;
    this.sortDesc = options.sortDesc || false;
    this.enable = options.enable || false;
    this.cssClass = options.cssClass || 'left';
    this.pipes = options.pipes || [];
    this.tooltipPipes = options.tooltipPipes || [];
    this.defaultFilter = options.defaultFilter;
    this.field = options.field;
    this.sortable = options.sortable ?? true;
    this.dynCssClass = options.dynCssClass;
  }

  public equals(config: ApDynGridColumnConfigBase): boolean {
    return false;
  }

  protected getTooltip(func: (dataItem: any) => any): (item: any) => any {
    return (dataItem) => dataItem === undefined ? undefined : func(dataItem);
  }
}
