import {Injectable}               from '@angular/core';
import {AgriportConstantsService} from './agriport-constants.service';
import INUptakeFactors = Data.Common.INUptakeFactors;

@Injectable({providedIn: 'root'})
export class NuptakeCalculationService {
  constructor(private agriportConstantsService: AgriportConstantsService) {
  }

  public calculateNUptakeValue(nIndexValue: number, nUptakeFactor: INUptakeFactors): number | null {
    if (!nUptakeFactor) {
      return null;
    }
    const slopeConst = this.agriportConstantsService.GetConstant('slope_gbi_ndi');
    const intercConst = this.agriportConstantsService.GetConstant('interc_gbi_ndi');
    const powerConst = this.agriportConstantsService.GetConstant('power_gbi_nindex');    
    if (!slopeConst || !intercConst|| !powerConst) {
      return null;
    }
    const result = (+powerConst*Math.pow(nIndexValue, 2)+ +slopeConst * nIndexValue + +intercConst) * nUptakeFactor.Slope + nUptakeFactor.Interc;
    return Number.isFinite(result) && result >= 0 ? result : 0;
  }
}
