import {IStateStoreWritable} from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {
  ApSignalrService
}                            from '../../ap-core/services/ap-signalr.service';
import {
  CropApplicationLoad,
  CropApplicationLoadSuccess
}                            from '../../../../projects/invoker-transport/src/lib/actions/task-management';
import {CropTypeStore}       from '../base-data/crop.types.store';
import {NFertilizationStore} from '../n-fertilization/n-fertilization.store';
import ICropApplNum = Data.PfBox.ICropApplNum;
import IDatasetFieldMap = Data.Sensor.IDatasetFieldMap;
import INFertilizationSummary = Data.TaskManagement.INFertilizationSummary;
import IOperationMode = Data.Common.IOperationMode;

interface ITaskManagementStore extends IStateStoreWritable<ICropApplNum> {
}

@Injectable({providedIn: 'root'})
export class CropApplicationStore extends Store<ITaskManagementStore> {
  public static _getMost(arr: any[]): any {
    return (arr || []).reduce((acc, el) => {
      acc.k[el] = acc.k[el] ? acc.k[el] + 1 : 1;
      acc.max = acc.max ? acc.max < acc.k[el] ? el : acc.max : el;
      return acc;
    }, {k: {}, max: undefined}).max;
  }

  constructor(public backend: ApSignalrService,
              private cropTypeStore: CropTypeStore,
              private nFertilizationStore: NFertilizationStore) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      saved: false,
      saving: false
    });

    backend.registerObservable(CropApplicationLoadSuccess).subscribe((d) => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });

    setTimeout(() => {
      super.SetLoadState();
      this.DispatchBackend(new CropApplicationLoad([]));
    }, 100);
  }

  GetApplication(eppoCode: string, ec: number, operatonMode?: IOperationMode): number {
    if (operatonMode?.Key === 'Worktype_Category_Bonitur') {
      return -1;
    }
    if (ec === 0 || super.Listen(s => s.data).getValue().every(v => ec < v.EcMin)) {
      return 0;
    }
    return super.Listen(s => s.data).getValue()
      .Find(v => v?.EppoCode === eppoCode && v?.EcMin <= ec && ec <= v?.EcMax)?.ApplNum ?? undefined;
  }

  GetApplicationFromLogfile(logfile: IDatasetFieldMap): number {
    return this.GetApplication(this.cropTypeStore.getDefaultCroptype(logfile.CropType).Eppocode, logfile.Ec);
  }

  GetApplicationFromSummary(summary: INFertilizationSummary): number {
    if (!summary) {
      return 0;
    }
    const planBooks = this.nFertilizationStore.getPlanningsBySummaryId(summary.Id);
    const cropKey = CropApplicationStore._getMost(planBooks.map((pB) => this.cropTypeStore.getDefaultCroptype(pB?.MainCropId)?.CropKey));
    return this.GetApplication(cropKey, summary.ECValue, summary.OperationMode);
  }
}
