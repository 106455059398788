import {IStateStore}                                                           from '../../ap-interface';
import {Injectable}                                                            from '@angular/core';
import {Store}                                                                 from '../index';
import {
  ApSignalrService
}                                                                              from '../../ap-core/services/ap-signalr.service';
import {MonitoringTypeLoad, MonitoringTypeLoadFail, MonitoringTypeLoadSuccess} from 'invoker-transport';
import {SafeBehaviorSubject}                                                   from 'ts-tooling';
import IMonitoringType = Data.Fields.IMonitoringType;

interface IMonitoringTypeStore extends IStateStore<IMonitoringType> {
}

@Injectable({providedIn: 'root'})
export class MonitoringTypeStore extends Store<IMonitoringTypeStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: []
    });
    backend.registerObservable(MonitoringTypeLoad).subscribe(_ => {
      super.SetLoadState();
    });
    backend.registerObservable(MonitoringTypeLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(MonitoringTypeLoadFail).subscribe(_ => {
      super.SetLoadFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get MonitoringTypes$(): SafeBehaviorSubject<IMonitoringType[]> {
    return this.Listen((s) => s.data);
  }

  public get MonitoringTypes(): IMonitoringType[] {
    return this.MonitoringTypes$.getValue();
  }

  public loadMonitoringTypes(): void {
    this.DispatchBackend(new MonitoringTypeLoad([]));
  }
}
