<div [ngStyle]="containerStyles"
     [style.display]="'flex'"
     [style.width]="'99%'">
  <div [aptooltip]="tooltipValue"
       [perpetual]="enableTooltip"
       [ngStyle]="barStyles"
       [style.width]="value * 100 / maximum + '%'"
       [style.height]="'20px'">
    <span *ngIf="showValue">{{valueText ?? value}}</span>
  </div>
</div>
