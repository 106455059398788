import {Pipe, PipeTransform} from '@angular/core';
import {Trace}               from '../../../debug-utils/ApplicationTracer';
import {ApDynGridColumnConfigBase} from '../../config/ap-dyn-grid-column-config-base';
import {ApDynGridIconColumnConfig} from '../../config/ap-dyn-grid-icon-column-config';

@Pipe({
  name: 'asIcon'
})
export class AsIconPipe implements PipeTransform {

  @Trace()
  transform(config: ApDynGridColumnConfigBase): ApDynGridIconColumnConfig {
    return config as ApDynGridIconColumnConfig;
  }

}
