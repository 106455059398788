import {IAction, IApInvokerArguments} from '../../interfaces';

enum MonitoringTypeActions {
  MONITORING_TYPE_LOAD = '[monitoring-type] load',
  MONITORING_TYPE_LOAD_SUCCESS = '[monitoring-type] load success',
  MONITORING_TYPE_LOAD_FAIL = '[monitoring-type] load fail',
}

export class MonitoringTypeLoad implements IAction {
  readonly type = MonitoringTypeActions.MONITORING_TYPE_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringTypeLoadSuccess implements IAction {
  readonly type = MonitoringTypeActions.MONITORING_TYPE_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringTypeLoadFail implements IAction {
  readonly type = MonitoringTypeActions.MONITORING_TYPE_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}
