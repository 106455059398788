import {IAction, IApInvokerArguments} from '../../interfaces';
import ICropApplNum = Data.PfBox.ICropApplNum;

export enum CropApplicationActions {
  CROP_APPLICATION_LOAD = '[crop application] load',
  CROP_APPLICATION_LOAD_SUCCESS = '[crop application] load success',
}

export class CropApplicationLoad implements IAction {
  readonly type = CropApplicationActions.CROP_APPLICATION_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class CropApplicationLoadSuccess implements IAction {
  readonly type = CropApplicationActions.CROP_APPLICATION_LOAD_SUCCESS;

  constructor(public payload: ICropApplNum[]) {
  }
}
