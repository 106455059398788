export class ApDefined {
  public static async waitUntilDefinedAsync<T extends object>(
    object: T,
    functionName: keyof T,
    maxWaitTime: number = 2000,
    interval: number = 100
  ): Promise<void> {
    const startTime = Date.now();

    while (typeof object[functionName] !== 'function') {
      if (Date.now() - startTime > maxWaitTime) {
        throw new Error(`Timeout: Function ${String(functionName)} not defined within ${maxWaitTime}ms`);
      }
      await new Promise(resolve => setTimeout(resolve, interval));
    }
  }

  /**
   * Waiting until property or function is defined inside the object given maxWaitTime.
   * Error will be thrown if property/function is not defined within maxWaitTime.
   * @param object Object to check for property
   * @param propertyName name of the function
   * @param callback callback function to process when property is defined
   * @param maxWaitTime time to wait in milliseconds, 2000 by default
   * @param interval interval to check for property, by default 50
   */
  public static waitUntilDefined<T extends object>(
    object: T,
    propertyName: keyof T,
    callback: (error?: Error) => void,
    maxWaitTime: number = 2000,
    interval: number = 100
  ): void {
    const startTime = Date.now();

    const checkFunction = () => {
      if (typeof object[propertyName] !== 'undefined') {
        callback();
      } else if (Date.now() - startTime > maxWaitTime) {
        throw new Error(`Timeout: Property ${String(propertyName)} not defined within ${maxWaitTime}ms`);
      } else {
        setTimeout(checkFunction, interval);
      }
    };

    checkFunction();
  }
}
