import {NgModule}                                from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ApMenuUrls, PageMode}                    from './ap-interface';
import {ApPageNotFoundComponent}                 from './layout/components/ap-page-not-found.component';

// @ts-ignore
const ROUTES: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/ap-login.module').then(m => m.ApLoginModule),
    data: {
      login: false,
      layout: PageMode.FULLSCREEN
    }
  },
  {
    path: 'administration',
    loadChildren: () => import('./administration/ap-administration.module').then(m => m.ApAdministrationModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/ap-settings.module').then(m => m.ApSettingsModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN,
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/ap-dashboard.module').then(m => m.ApDashboardModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'basedata',
    loadChildren: () => import('./basedata/ap-basedata.module').then(m => m.ApBaseDataModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'fieldwork',
    loadChildren: () => import('./docu/ap-docu.module').then(m => m.ApDocuModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'evaluation',
    loadChildren: () => import('./evaluation/ap-evaluation.module').then(m => m.ApEvaluationModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'fieldmanagement',
    loadChildren: () => import('./field-management/ap-field-management.module').then(m => m.ApFieldManagementModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'nutrients',
    loadChildren: () => import('./nutrient-management/ap-nutrient-management.module').then(m => m.ApNutrientManagementModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'n-fertilization',
    loadChildren: () => import('./n-fertilization/ap-n-fertilization.module').then(m => m.ApNFertilizationModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'plant-protection',
    loadChildren: () => import('./plant-protection/plant-protection.module').then(m => m.PlantProtectionModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'booking',
    loadChildren: () => import('./booking/ap-booking.module').then(m => m.ApBookingModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'task-management',
    loadChildren: () => import('./task-management/task-management.module').then(m => m.TaskManagementModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'import',
    loadChildren: () => import('./import/ap-import.module').then(m => m.ApImportModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'info',
    loadChildren: () => import('./info/ap-info.module').then(m => m.ApInfoModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('./ap-reports/ap-reports.module').then(m => m.ApReportsModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: 'farm-records',
    loadChildren: () => import('./ap-farm-records/ap-farm-records.module').then(m => m.ApFarmRecordsModule),
    data: {
      login: true,
      layout: PageMode.HALFSCREEN
    }
  },
  {
    path: '',
    redirectTo: ApMenuUrls.LOGIN,
    pathMatch: 'full'
  },
  {
    path: '**',
    component: ApPageNotFoundComponent,
    data: {
      login: false,
      layout: PageMode.HALFSCREEN
    }
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      relativeLinkResolution: 'legacy',
      preloadingStrategy: PreloadAllModules,
    })
  ],
  exports: [
    RouterModule
  ]
})
export class RoutesModule {
}
