import {Injectable}           from '@angular/core';
import {ApTranslationService} from '../../ap-utils/service/ap-translation.service';
import IUnit = Data.Common.IUnit;

@Injectable({providedIn: 'root'})
export class UnitService {
  constructor(private translationService: ApTranslationService) {
  }

  public getUnitWithLabelKey(unit: IUnit | null, labelKey: string, perHa: boolean = true, useDefaultUnit: boolean = true): string {
    const fertilizerUnitName = this._getUnitTranslation(unit, perHa, useDefaultUnit);
    const translatedLableKey = this.translationService.translate(labelKey);
    if (fertilizerUnitName) {
      return `${translatedLableKey} [${fertilizerUnitName}]`;
    }
    return `${translatedLableKey}`;
  }

  private _getUnitTranslation(unit: IUnit | null, perHa = false, defaultUnit: boolean = true): string {
    let unitValue: string;
    if (unit) {
      const unitKey = unit.BaseUnit ? unit.BaseUnit.Key : unit.Key;
      unitValue = this.translationService.translate(unitKey);
    } else {
      if (defaultUnit) {
        unitValue = this.translationService.translate('Global__Unit_kg');
      } else {
        return '';
      }
    }
    if (perHa) {
      return `${unitValue}/${this.translationService.translate('Global__Unit_ha')}`;
    } else {
      return unitValue;
    }
  }
}
