import IGuid = System.IGuid;

/**
 * Represents N-application entry with field, fertilization and uptake data
 */
export interface IContentNRelatedPlanBook {
  PlanBookId: IGuid;
  FieldId: IGuid;
  OperationModeId: number | null;
  ProductId: number | null;
  FieldGeomId: IGuid;
  CropTypeId: number | null;
  EcValue: number;
  ApplicationDate: Date;
  NUptake: IContentNRelatedPlanBookStatisticValue | null;
  NFertilization: IContentNRelatedPlanBookStatisticValue | null;
  SourceType: ContentNRelatedPlanBookSourceType;
}

/**
 * Statistical values for N-application measurements
 */
export interface IContentNRelatedPlanBookStatisticValue {
  Min: number;
  Max: number;
  Average: number;
}

/**
 * Defines the origin source of the plan book data
 */
export enum ContentNRelatedPlanBookSourceType {
  LogFilePlanBook = 0,
  NFertPlanBook = 1,
  BaseFertPlanBook = 2
}
