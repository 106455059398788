import {NgModule}                         from '@angular/core';
import {CommonModule}                     from '@angular/common';
import {ApEditFieldComponent}             from './ap-edit-field.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DatePickerModule}                 from '@progress/kendo-angular-dateinputs';
import {TabStripModule}                   from '@progress/kendo-angular-layout';
import {ApTranslationModule}              from '../../translation/ap-translation.module';
import {ApCoreModule}                     from '../../ap-core/ap-core.module';
import {ComboBoxModule}                   from '@progress/kendo-angular-dropdowns';
import {ButtonModule}                     from '@progress/kendo-angular-buttons';
import {ApResponsiveTextModule}           from '../../ap-responsive-text/ap-responsive-text.module';
import {ApDynformsModule}                 from '../../ap-dynforms/ap-dynforms.module';
import {ApDynComponentModule}             from '../../ap-dyncomponent/ap-dyncomponent.module';
import {ApUtilsModule}                    from '../../ap-utils';
import {ApSectionHeaderModule}            from '../../ap-section-header/ap-section-header.module';
import {ApParameterEntryComponentsModule} from '../ap-parameter-entry-components/ap-parameter-entry-components.module';
import {ApMergeFieldComponent}            from './ap-merge-field.component';
import {ApSplitFieldComponent}            from './ap-split-field.component';
import {ApMonitoringFieldEntryComponent}  from './ap-monitoring-field/ap-monitoring-field-entry.component';
import {ApMonitoringDetailEntryComponent} from './ap-monitoring-detail/ap-monitoring-detail-entry.component';

const COMPONENTS = [
  ApEditFieldComponent,
  ApMergeFieldComponent,
  ApSplitFieldComponent,
  ApMonitoringFieldEntryComponent,
  ApMonitoringDetailEntryComponent
];

@NgModule({
  declarations: COMPONENTS,
  entryComponents: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    DatePickerModule,
    TabStripModule,
    ReactiveFormsModule,
    ApTranslationModule,
    ApCoreModule,
    ComboBoxModule,
    ButtonModule,
    ApResponsiveTextModule,
    ApDynformsModule,
    ApDynComponentModule,
    ApUtilsModule,
    ApSectionHeaderModule,
    ApParameterEntryComponentsModule,
  ],
  exports: COMPONENTS
})
export class ApFieldManagementEntryComponentsModule {
}
