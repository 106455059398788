import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, combineLatest, Subscription}                                            from 'rxjs';
import {
  FieldStore
}                                                                                                from '../../../stores/farm/field.store';
import {distinctUntilChanged, filter, map}                                                       from 'rxjs/operators';
import {
  ApDynGridPagerWizardConfig
}                                                                                                from '../../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {
  ApDynGridColumnConfigBase
}                                                                                                from '../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  SettingsStore
}                                                                                                from '../../../stores/base-data/settings.store';
import {
  MapViewCurrentMenu,
  MapViewMode
}                                                                                                from '../../../ap-interface';
import {
  MapViewStore
}                                                                                                from '../../../stores/layout/mapview.store';
import {
  ApDynComponentComponent
}                                                                                                from '../../../ap-dyncomponent/ap-dyncomponent.component';
import {
  MonitoringFieldGridItem
}                                                                                                from './monitoring-field-entry.types';
import {
  FormStore
}                                                                                                from '../../../stores/layout/form.store';
import {
  MonitoringFieldStore
}                                                                                                from '../../../stores/fields/monitoring-field.store';
import {
  MonitoringFieldEntryConfig
}                                                                                                from './monitoring-field-entry.config';
import {
  ApDynformsConfigFieldset
}                                                                                                from '../../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  MonitoringDetailEntryConfig
}                                                                                                from '../ap-monitoring-detail/monitoring-detail-entry.config';
import {
  MonitoringTypeStore
}                                                                                                from '../../../stores/fields/monitoring-type.store';
import {
  CampaignYearStore
}                                                                                                from '../../../stores/login/campaignyear.store';
import {
  MonitoringDetailEntryFormData
}                                                                                                from '../ap-monitoring-detail/monitoring-detail-entry.types';
import IGuid = System.IGuid;
import IMonitoringDetail = Data.Fields.IMonitoringDetail;

@Component({
  selector: 'ap-monitoring-field-entry',
  template: `
    <ap-dyncomponent [caption]="caption$ | async"
                     [columns]="columns$ | async"
                     [fieldSets]="fieldSets$ | async"
                     [items]="items$ | async"
                     [loading$]="loading$"
                     [pager]="pager"
                     [headerIcon]="'ap-icon-fields'"
                     [canSearch]="true"
                     [canCreate]="false"
                     [selectable]="{checkboxOnly: false, mode: 'single', enabled: true}">
      <div class="ap-form-actions" dynforms.action>
        <div apResponsive
             [sizeMd]="'25-100'"
             [style.text-align]="'left'">
          <button kendoButton
                  [id]="'button_prev'"
                  [type]="'button'"
                  [class]="'k-button k-primary button-important'"
                  (click)="onBackToSelection.emit()">
            <ap-responsive-text [key]="'Global_Back_With_Arrow'"></ap-responsive-text>
          </button>
        </div>
        <div apResponsive
             [sizeMd]="'50-100'"
             [style.text-align]="'center'">
          <ap-breadcrumbs-steper [columns]="monitoringFieldEntryConfig.getBreadCrumbs()"
                                 [selected]="1">
          </ap-breadcrumbs-steper>
        </div>
        <div apResponsive
             [sizeMd]="'25-100'"
             [style.text-align]="'right'">
          <button kendoButton
                  [id]="'button_save'"
                  [disabled]="!dynComponent.valid"
                  [type]="'button'"
                  [class]="'k-button k-primary button-important'"
                  (click)="this.onSave.emit()">
            <ap-responsive-text [key]="'Global__Save'"></ap-responsive-text>
          </button>
        </div>
      </div>
    </ap-dyncomponent>
  `,
  styles: [],
  providers: [
    MonitoringFieldEntryConfig,
    MonitoringDetailEntryConfig
  ]
})
export class ApMonitoringFieldEntryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(ApDynComponentComponent, {static: true}) public dynComponent: ApDynComponentComponent;
  public columns$ = new BehaviorSubject<ApDynGridColumnConfigBase[]>([]);
  public loading$ = new BehaviorSubject<boolean>(true);
  public items$ = new BehaviorSubject<MonitoringFieldGridItem[]>([]);
  public fieldSets$ = new BehaviorSubject<ApDynformsConfigFieldset[]>([]);
  public caption$ = new BehaviorSubject<string>('');
  public onBackToSelection = new EventEmitter<void>();
  public onSave = new EventEmitter<void>();
  public pager: ApDynGridPagerWizardConfig;

  private _onCancel = new EventEmitter<void>();
  private _onNextToDetails = new EventEmitter<void>();
  private _isNextActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _selectionSubscriptions: Subscription[] = [];
  private _detailsSubscriptions: Subscription[] = [];
  private _subscriptions: Subscription[] = [];
  private _selectedField: IGuid | undefined;

  constructor(private formStore: FormStore,
              private fieldStore: FieldStore,
              private mapViewStore: MapViewStore,
              private settingsStore: SettingsStore,
              private campaignYearStore: CampaignYearStore,
              private monitoringTypeStore: MonitoringTypeStore,
              private monitoringFieldStore: MonitoringFieldStore,
              private changeDetection: ChangeDetectorRef,
              public monitoringDetailEntryConfig: MonitoringDetailEntryConfig,
              public monitoringFieldEntryConfig: MonitoringFieldEntryConfig) {
  }

  public ngOnInit(): void {
    this._handleHalfMapAndGridView();
    this._onSelectionStep();
  }

  public ngAfterViewInit(): void {
    this._subscriptions.push(this._onCancel.subscribe(() => {
      this._closeForm();
    }));
    this._subscriptions.push(this._onNextToDetails.subscribe((): void => {
      this._onDetailsStep();
    }));
    this._subscriptions.push(this.onBackToSelection.subscribe((): void => {
      this._onSelectionStep();
    }));
    this._subscriptions.push(this.onSave.subscribe(() => {
      const monitoringDetailData = this.dynComponent.dynForm.form.getRawValue() as MonitoringDetailEntryFormData;
      const monitoringType = this.monitoringTypeStore.MonitoringTypes.find(x => x.Id === monitoringDetailData.MonitoringTypeId);
      const newMonitoringDetail = {
        TypeId: monitoringType.Id,
        Date: monitoringDetailData.Date,
        EcStage: monitoringDetailData.EcValue,
        Notes: monitoringDetailData.Notes,
        Value: monitoringDetailData.Value,
        ValueUnit: monitoringType.ValueUnit,
        Recommendation: monitoringDetailData.Recommendation,
        RecommendationUnit: monitoringType.RecommendationUnit
      } as IMonitoringDetail;
      this.monitoringFieldStore.saveMonitoringFieldWithDetail(this._selectedField, newMonitoringDetail);
      this._closeForm();
    }));
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(x => x?.unsubscribe());
    this._detailsSubscriptions.forEach(x => x?.unsubscribe());
    this._selectionSubscriptions.forEach(x => x?.unsubscribe());
    this.fieldStore.changeSelectedField([]);
  }

  /**
   * Resets form state and configures pager for field selection step
   * Cleans up detail subscriptions and initializes selection mode
   * @private
   */
  private _onSelectionStep(): void {
    this._detailsSubscriptions.forEach(x => x.unsubscribe());
    this.fieldSets$.next([]);
    this.caption$.next('NewMonitoringField');
    this.pager = this.monitoringFieldEntryConfig.getSelectionStepPager(this._onCancel, this._onNextToDetails,
      this._isNextActive.asObservable());
    this._setSelectionSubscriptions();
  }

  /**
   * Transitions form to details entry mode
   * Cleans up selection subscriptions and prepares for monitoring details
   * @private
   */
  private _onDetailsStep(): void {
    this._selectionSubscriptions.forEach(x => x.unsubscribe());
    this.columns$.next([]);
    this.caption$.next('NewMonitoringDetail');
    this._setDetailsSubscriptions();
  }

  /**
   * Sets up reactive subscriptions for field selection grid
   * Handles loading states, field filtering, grid updates and selection events
   * @private
   */
  private _setSelectionSubscriptions(): void {
    this._selectionSubscriptions.push(
      combineLatest([
        this.fieldStore.Loading$,
        this.monitoringFieldStore.Loading$
      ]).pipe(
        map((loadings) => loadings.some(loaded => loaded)),
        distinctUntilChanged()
      ).subscribe(loading => {
        this.loading$.next(loading);
      })
    );
    this._selectionSubscriptions.push(
      combineLatest([
        this.fieldStore.Fields$,
        this.monitoringFieldStore.MonitoringFields$
      ]).pipe(
        map(([fields, monitoringFields]) => {
          return fields.filter(field => !monitoringFields.some(x => x.FieldId === field.Id))
            .map(field => this.monitoringFieldEntryConfig.getMonitoringFieldGridItem(field));
        })
      ).subscribe(items => {
        this.items$.next(items);
        if (!!this._selectedField) {
          this.dynComponent.dynGrid.gridPaging.setSelectedKeys([this._selectedField]);
          this._isNextActive.next(true);
        }
      })
    );
    this._selectionSubscriptions.push(
      combineLatest([
        this.settingsStore.FirstSetting$
      ]).pipe(
        map(([setting]) => this.monitoringFieldEntryConfig.getGridColumns(setting))
      ).subscribe(columns => {
        this.columns$.next(columns);
      })
    );
    this._selectionSubscriptions.push(this.dynComponent.SelectedItems$.subscribe((items: MonitoringFieldGridItem[]) => {
      const selectedFieldIds = items.map(x => x.Id.toString());
      this.fieldStore.changeSelectedField(selectedFieldIds);
      if (selectedFieldIds.length <= 0) {
        this.fieldStore.zoomFarmFields();
      }
      this._selectedField = selectedFieldIds.length > 0 ? selectedFieldIds[0] : undefined;
      this._isNextActive.next(!!this._selectedField);
    }));
  }

  /**
   * Configures subscriptions for monitoring details form
   * Manages form configuration based on monitoring types and campaign year
   * @private
   */
  private _setDetailsSubscriptions(): void {
    this._detailsSubscriptions.push(
      combineLatest([
        this.monitoringTypeStore.MonitoringTypes$,
        this.campaignYearStore.SelectedCampaignYear$,
        this.fieldSets$.asObservable()
      ]).pipe(
        filter(([monitoringTypes, campaignYear, formConfig]) => {
          return !(monitoringTypes.length <= 0 || !campaignYear || formConfig.length > 0);
        }),
        map(([monitoringTypes, campaignYear, _]) => {
          const formData = this.monitoringDetailEntryConfig.getFormData(campaignYear, undefined, monitoringTypes);
          return this.monitoringDetailEntryConfig.generateFormConfig(formData, this.dynComponent.dynForm.FormValues$);
        })
      ).subscribe(formConfig => {
        this.fieldSets$.next(formConfig);
        setTimeout(() => {
          this.dynComponent.dynForm.form.markAllAsTouched();
        }, 1);
        this.changeDetection.detectChanges();
      })
    );
  }

  /**
   * Manages the map and grid view state:
   * 1. Clears selected fields
   * 2. Ensures proper map view mode
   * 3. Adjusts zoom to show all farm fields
   * @private
   */
  private _handleHalfMapAndGridView(): void {
    this.fieldStore.changeSelectedField([]);
    if (this.mapViewStore.getMapViewMode() !== MapViewMode.NORMAL) {
      this.mapViewStore.goIntoMapMenu();
    }
    if (this.mapViewStore.getMapViewCurrentMenu() !== MapViewCurrentMenu.MAP) {
      this.mapViewStore.goIntoMapMenu();
    }
    this.fieldStore.zoomFarmFields();
  }

  /**
   * Closes the form with a slight delay to prevent UI issues.
   * Uses setTimeout to push closure to next event loop cycle.
   * @private
   */
  private _closeForm(): void {
    setTimeout(() => this.formStore.closeForm(), 1);
  }
}
