import {IStreamWatch, Store}                from '../index';
import {
  ApCustomTypes,
  LicenseAdjustRequest,
  LicenseFarmLoad,
  LicenseFarmLoadError,
  LicenseFarmLoadSuccess,
  LicenseSaveFailed,
  LicenseSaveSuccess,
  LicenseUpdate
}                                           from 'invoker-transport';
import {IStateStoreWritable}                from '../../ap-interface';
import {ObjectFactory, SafeBehaviorSubject} from 'ts-tooling';
import {Injectable}                         from '@angular/core';
import {
  NetTypes
}                                           from '../../../../projects/invoker-transport/src/lib/generated/backend.enums';
import {ApSignalrService}                   from '../../ap-core/services/ap-signalr.service';
import ILicense = Data.Licensing.ILicense;
import LicenseAdjustAction = Data.Licensing.Enums.LicenseAdjustAction;

interface ILicenseStore extends IStateStoreWritable<ILicense> {
}

@Injectable({providedIn: 'root'})
export class LicenseStore extends Store<ILicenseStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      data: [],
      loaded: false,
      loading: false,
      saved: false,
      saving: false
    });
    backend.registerObservable(LicenseFarmLoad).subscribe(_ => {
      super.Mutate(s => s.data, () => []);
      super.SetLoadState();
    });
    backend.registerObservable(LicenseFarmLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(LicenseFarmLoadError).subscribe(_ => {
      super.Mutate(s => s.data, () => []);
      super.SetLoadFinishState();
    });
    backend.registerObservable(LicenseSaveSuccess).subscribe(_ => {
      this.SetSaveSuccessState();
    });
    backend.registerObservable(LicenseSaveFailed).subscribe(_ => {
      this.SetSaveFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get licenseLoading$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.loading);
  }

  public get licenseData$(): SafeBehaviorSubject<ILicense[]> {
    return this.Listen(s => s.data);
  }

  public loadLicensesForFarm(): void {
    this.DispatchBackend(new LicenseFarmLoad([]));
  }

  public updateLicense(license: ILicense, isExtendLicense: boolean): void {
    this.SetSaveState();
    this.DispatchBackend(new LicenseUpdate([
      {Name: 'license', Type: ApCustomTypes.Data_Licensing_License, Value: license},
      {Name: 'isExtendLicense', Type: NetTypes.BOOL, Value: isExtendLicense}
    ]));
  }

  public adjustLicenseRequest(featureModuleKey: string, adjustAction: LicenseAdjustAction): IStreamWatch {
    return this.DispatchBackend(new LicenseAdjustRequest([
      {Name: 'featureModuleKey', Type: NetTypes.STRING, Value: featureModuleKey},
      {Name: 'adjustAction', Type: ApCustomTypes.Data_Licensing_Enums_LicenseAdjustAction, Value: adjustAction}
    ]));
  }

  public setLicenseEmpty(): void {
    super.Mutate(s => s, () =>
      ({
        data: [],
        loaded: false,
        loading: false,
        saving: false,
        saved: false
      } as ILicenseStore));
  }

  /**
   * override the UpsertData with a custom UpsertData Strategy
   */
  UpsertData(position: number, item: any): void {
    this.Mutate(s => s.data, licenses => {
      if (position >= 0) {
        this._updateItems(licenses, item, position);
      } else {
        licenses.Add(item);
      }
      return licenses;
    });
  }

  private _updateItems(licenses: ILicense[], updatedLicense: ILicense, position: number): void {
    if (!licenses || !Array.isArray(licenses)) {
      return;
    }
    const itemToReplace = licenses[position];
    updatedLicense.TrialActivationsCount = itemToReplace.TrialActivationsCount;
    if (!ObjectFactory.Equal(itemToReplace, updatedLicense)) {
      updatedLicense.TrialActivationsCount += 1;
    }
    licenses[position] = updatedLicense;
  }
}
