import {EventEmitter, Injectable} from '@angular/core';
import {
  ApDynGridStatusColumnConfig
}                                 from '../../../ap-dyngrids/config/ap-dyn-grid-status-column-config';
import {
  nameOf
}                                 from '../../../ap-core/utils/ap-name-of';
import {
  MonitoringFieldGridItem
}                                 from './monitoring-field-entry.types';
import {
  ApDynGridPropertyColumnConfig,
  FilterType
}                                 from '../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  ApDynGridColumnConfigBase,
  ApDynGridColumnHideConfig
}                                 from '../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  map
}                                 from 'rxjs/operators';
import {
  MapViewMode
}                                 from '../../../ap-interface';
import {
  MapViewStore
}                                 from '../../../stores/layout/mapview.store';
import {
  GetRoundNumericPipe
}                                 from '../../../ap-utils';
import {
  ApDynGridPagerWizardBreadcrumbsStepper,
  ApDynGridPagerWizardButton,
  ApDynGridPagerWizardConfig
}                                 from '../../../ap-dyngrids/config/ap-dyn-grid-pager-wizard-config';
import {Observable}               from 'rxjs';
import {
  FieldStore
}                                 from '../../../stores/farm/field.store';
import {
  IGridStatus
}                                 from '../../../ap-interface/interfaces/ap-grid-status.interface';
import {
  ApColumnState
}                                 from '../../../ap-interface/enums/ap-column-state.enum';
import {
  ApGetCropService
}                                 from '../../../stores/services/ap-get-crop.service';
import ISettings = Data.BaseData.ISettings;
import IField = Data.FieldManagement.IField;

@Injectable()
export class MonitoringFieldEntryConfig {
  constructor(private fieldStore: FieldStore,
              private mapViewStore: MapViewStore,
              private cropService: ApGetCropService,
              private roundNumericPipe: GetRoundNumericPipe) {
  }

  /**
   * Returns breadcrumb labels for wizard steps
   * @returns Array of translation keys for breadcrumbs
   * @public
   */
  public getBreadCrumbs(): string[] {
    return ['Global__Selection', 'Global__Details'];
  }

  /**
   * Generates grid column configuration for monitoring field display.
   * @param setting Application settings containing decimal point preferences.
   * @returns Array of column configurations.
   * @public
   */
  public getGridColumns(setting: ISettings): ApDynGridColumnConfigBase[] {
    return [
      new ApDynGridStatusColumnConfig({
        field: nameOf<MonitoringFieldGridItem>('Status'),
        class: nameOf<MonitoringFieldGridItem>('StatusClass'),
        description: nameOf<MonitoringFieldGridItem>('StatusDesc'),
        filterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini__Number',
        field: nameOf<MonitoringFieldGridItem>('FieldNumber'),
        width: 60,
        cssClass: 'right',
        hide: true,
        filterable: true,
        filterType: FilterType.NUMBER,
        headerFilterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini_Subnumber',
        field: nameOf<MonitoringFieldGridItem>('FieldSecondNumber'),
        width: 60,
        cssClass: 'right',
        hide: true,
        filterable: true,
        filterType: FilterType.NUMBER,
        headerFilterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini__Number',
        field: nameOf<MonitoringFieldGridItem>('FieldCombinedNumber'),
        width: 70,
        hide: new ApDynGridColumnHideConfig({
          selfHide: this.mapViewStore.Listen(s => s.mode).pipe(
            map((mode) => mode === MapViewMode.HIDE)
          )
        }),
        filterable: true,
        headerFilterable: true,
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini__Name',
        field: nameOf<MonitoringFieldGridItem>('FieldName'),
        width: 180,
        sortIndex: 0,
        filterable: true,
        headerFilterable: true
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Docu_Ini__Area',
        field: nameOf<MonitoringFieldGridItem>('Area'),
        width: 60,
        cssClass: 'right',
        tooltip: (dataItem: MonitoringFieldGridItem) => dataItem?.Area?.toString(),
        filterable: true,
        filterType: FilterType.NUMBER,
        headerFilterable: true,
        pipes: [{
          pipe: this.roundNumericPipe,
          args: [setting.DigitsAfterDecimalPoint]
        }]
      }),
      new ApDynGridPropertyColumnConfig({
        title: 'Global__Crop',
        field: nameOf<MonitoringFieldGridItem>('CropName'),
        width: 100,
        filterable: true,
        headerFilterable: true
      })
    ];
  }

  /**
   * Creates pager configuration with cancel and save buttons.
   * @param onPrev Event emitter for cancel action.
   * @param onNext Event emitter for save action.
   * @param isNextActive Observable controlling save button state.
   * @returns Wizard-style pager configuration with two buttons separated by a section.
   * @public
   */
  public getSelectionStepPager(onPrev: EventEmitter<void>,
                               onNext: EventEmitter<void>,
                               isNextActive: Observable<boolean>): ApDynGridPagerWizardConfig {
    return new ApDynGridPagerWizardConfig(
      new ApDynGridPagerWizardButton('Global__Cancel', onPrev, {id: 'button_cancel'}),
      new ApDynGridPagerWizardBreadcrumbsStepper(this.getBreadCrumbs(), 0),
      new ApDynGridPagerWizardButton('Global_Next_With_Arrow', onNext, {
        id: 'button_next',
        active: isNextActive
      })
    );
  }

  /**
   * Generates monitoring field item for grid display
   * @param field Source field data
   * @returns Formatted grid item with status and field details
   * @public
   */
  public getMonitoringFieldGridItem(field: IField): MonitoringFieldGridItem {
    const fieldGeom = this.fieldStore.getCurrentFieldGeom(field);
    const fieldCombinedNumber = this.fieldStore.getFieldNumberAndSubNumber(field);
    const mainCropDescription = this.cropService.parseMainCrop(field);
    const gridStatus = this._getItemGridStatus(mainCropDescription);
    return {
      Id: field.Id,
      Status: gridStatus.state,
      StatusClass: gridStatus.class,
      StatusDesc: gridStatus.description,
      FieldName: field.FieldName,
      FieldNumber: field.FieldNumber,
      FieldSecondNumber: field.FieldSubnumber,
      FieldCombinedNumber: fieldCombinedNumber,
      Area: fieldGeom.AreaHa,
      CropName: mainCropDescription
    };
  }

  /**
   * Determines the grid status for a field based on its crop presence.
   * There is no crop - yellow status;
   * Crop is present for current year - green status;
   * @param mainCropDescription - The main crop description for crop for current year
   * @returns Status for grid
   * @private
   */
  private _getItemGridStatus(mainCropDescription: string | undefined): IGridStatus {
    const gridStatus: IGridStatus = {
      state: ApColumnState.Yellow,
      class: 'ap-status-yellow',
      description: ''
    };
    if (mainCropDescription) {
      gridStatus.state = ApColumnState.Green;
      gridStatus.class = 'ap-status-green';
    }
    return gridStatus;
  }
}
