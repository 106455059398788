import {AfterContentInit, Component, Input} from '@angular/core';
import {
  Observable,
  of
}                                           from 'rxjs';
import {
  SelectableSettings
}                                           from '@progress/kendo-angular-grid';
import {
  GetRoundNumericPipe
}                                           from '../../../../ap-utils';
import {
  ApDynGridPropertyColumnConfig
}                                           from '../../../../ap-dyngrids/config/ap-dyn-grid-property-column-config';
import {
  IPlanningWizardOverviewStatsItem,
  IPlanningWizardOverviewStatsTableData,
  PlanningWizardOverviewModuleStats
}                                           from './ap-planning-wizard-overview-stats-types';
import {
  ApDynGridColumnConfigBase
}                                           from '../../../../ap-dyngrids/config/ap-dyn-grid-column-config-base';
import {
  ApTranslationService
}                                           from '../../../../ap-utils/service/ap-translation.service';
import {
  ApDynGridGroupColumnConfig
}                                           from '../../../../ap-dyngrids/config/ap-dyn-grid-group-column-config';
import {
  FertilizerStore
}                                           from '../../../../stores/base-data/fertilizer.store';
import {
  UnitService
}                                           from '../../../../services/data/unit.service';

@Component({
  selector: 'ap-planning-wizard-overview-stats',
  templateUrl: 'ap-planning-wizard-overview-stats.component.html',
  styleUrls: ['ap-planning-wizard-overview-stats.scss']
})
export class ApPlanningWizardOverviewStatsComponent implements AfterContentInit {
  @Input() public data: IPlanningWizardOverviewStatsItem[];
  @Input() public title: string;
  @Input() public emptyMessage = '';
  public gridSelectableSettings: SelectableSettings = {
    enabled: false,
    checkboxOnly: false,
    mode: 'single',
    cell: false,
    drag: false
  };

  public statColumns: ApDynGridColumnConfigBase[] = [];
  public itemsStat$: Observable<IPlanningWizardOverviewStatsTableData[]>;

  constructor(private fertilizerStore: FertilizerStore,
              private unitService: UnitService,
              private translationService: ApTranslationService,
              private roundNumericPipe: GetRoundNumericPipe) {
  }

  public ngAfterContentInit(): void {
    if (!this.data || this.data.length <= 0) {
      return;
    }
    this.statColumns = this._getSumValuesStatsColumns(this.data[0]?.ProductId);
    this.itemsStat$ = of(this._getTotalData());
  }

  private _getTotalData(): IPlanningWizardOverviewStatsTableData[] {
    return this.data.reduce((gridData, currentItem, currentIndex, array) => {
      if (!currentItem?.OperationModeKey) {
        return gridData;
      }
      const items = array.filter(x => x.OperationModeKey === currentItem.OperationModeKey);
      const translatedOperationMode = this.translationService.translate(currentItem.OperationModeKey);
      if (items && items.length > 0 && gridData.every(x => x.OperationModeName !== translatedOperationMode)) {
        gridData.push({
          OperationModeName: translatedOperationMode,
          FieldsSum: items.length,
          AreaSum: this._getAreaSum(items),
          ProductAmountSum: this._getProductAmount(items),
          ProductMin: this._getProductMin(items),
          ProductMax: this._getProductMax(items),
          ProductAverage: this._getProductAverage(items),
          Average: this._getStatisticAverage(items)
        });
      }
      if (gridData && gridData.length > 0 && currentIndex === array.length - 1) {
        const totalArea = gridData.reduce((total, item) => total + (item.AreaSum ?? 0), 0);
        gridData.push({
          OperationModeName: '',
          FieldsSum: gridData.reduce((total, item) => total + item.FieldsSum, 0),
          AreaSum: gridData.reduce((total, item) => total + item.AreaSum, 0),
          ProductAmountSum: gridData.reduce((total, item) => total + item.ProductAmountSum, 0),
          ProductMin: gridData.reduce((prev, curr) => prev.ProductMin < curr.ProductMin ? prev : curr).ProductMin,
          ProductMax: gridData.reduce((prev, curr) => prev.ProductMax > curr.ProductMax ? prev : curr).ProductMax,
          ProductAverage: gridData.reduce((total, item) => total + (item.ProductAverage * item.AreaSum), 0) / totalArea,
          Average: gridData.reduce((total, item) => total + (item.Average * item.AreaSum), 0) / totalArea
        });
      }
      return gridData;
    }, [] as IPlanningWizardOverviewStatsTableData[]);
  }

  private _getAreaSum(items: IPlanningWizardOverviewStatsItem[]): number {
    return items.reduce((total, item) => total + (item.Area ?? 0), 0);
  }

  private _getProductAmount(items: IPlanningWizardOverviewStatsItem[]): number {
    return items.reduce((total, curr) => {
      let productAmount = (curr.Average ?? 0) * (curr.Area ?? 0);
      if (curr.Module === PlanningWizardOverviewModuleStats.NFertilization) {
        productAmount = productAmount / (curr?.NContent ?? 0);
      }
      return Number.isFinite(productAmount) ? total + productAmount : total;
    }, 0);
  }

  private _getProductMin(items: IPlanningWizardOverviewStatsItem[]): number {
    const minItem = items.reduce((prev, curr) => prev.Min < curr.Min ? prev : curr);
    let productMin = (minItem?.Min ?? 0);
    if (minItem.Module === PlanningWizardOverviewModuleStats.NFertilization) {
      productMin = productMin / (minItem?.NContent ?? 0);
    }
    return Number.isFinite(productMin) ? productMin : 0;
  }

  private _getProductMax(items: IPlanningWizardOverviewStatsItem[]): number {
    const maxItem = items.reduce((prev, curr) => prev.Max > curr.Max ? prev : curr);
    let productMax = (maxItem?.Max ?? 0);
    if (maxItem.Module === PlanningWizardOverviewModuleStats.NFertilization) {
      productMax = productMax / (maxItem?.NContent ?? 0);
    }
    return Number.isFinite(productMax) ? productMax : 0;
  }

  private _getProductAverage(items: IPlanningWizardOverviewStatsItem[]): number {
    const firstItem = items[0];
    const totalArea = this._getAreaSum(items);
    const productAverage = items.reduce((total, curr) => {
      const average = (curr.Average ?? 0) * (curr.Area ?? 0);
      return Number.isFinite(average) ? total + average : total;
    }, 0);
    let result = productAverage / totalArea;
    if (firstItem.Module === PlanningWizardOverviewModuleStats.NFertilization) {
      result = result / (firstItem.NContent ?? 0);
    }
    return Number.isFinite(result) ? result : 0;
  }

  private _getStatisticAverage(items: IPlanningWizardOverviewStatsItem[]): number {
    const totalArea = this._getAreaSum(items);
    const product = items.reduce((total, curr) => {
      if (curr.Module === PlanningWizardOverviewModuleStats.BaseFertilization) {
        return total + ((curr.NContent ?? 0) * (curr.Area ?? 0));
      }
      const productAmount = (curr.Average ?? 0) * (curr.Area ?? 0);
      return total + productAmount;
    }, 0);
    const result = product / totalArea;
    return Number.isFinite(result) ? result : 0;
  }

  private _getSumValuesStatsColumns(productId?: number): ApDynGridColumnConfigBase[] {
    const fertilizer = this.fertilizerStore.getFertilizer(productId);
    const productTitle = fertilizer
      ? this.unitService.getUnitWithLabelKey(fertilizer?.Unit, 'Global__Product', false)
      : 'Task_Mgmt_Product_kgha';
    return [
      new ApDynGridPropertyColumnConfig({
        field: 'OperationModeName',
        headerStyle: {'text-align': 'left'},
        style: {'font-weight': 600, 'background-color': 'silver'},
        cssClass: 'left',
        width: 100,
        filterable: false,
        sortable: false
      }),
      new ApDynGridGroupColumnConfig({
        title: 'Global__Sum',
        groupColumns: [
          new ApDynGridPropertyColumnConfig({
            title: 'Base__Fields',
            field: 'FieldsSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 60,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Docu_Ini__Area',
            field: 'AreaSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 70,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: productTitle,
            field: 'ProductAmountSum',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          })
        ]
      }),
      new ApDynGridGroupColumnConfig({
        title: productTitle,
        groupColumns: [
          new ApDynGridPropertyColumnConfig({
            title: 'Global__Min',
            field: 'ProductMin',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: 'Global__Max',
            field: 'ProductMax',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          }),
          new ApDynGridPropertyColumnConfig({
            title: `Global__Avg_Symbol`,
            field: 'ProductAverage',
            cssClass: 'right',
            headerStyle: {'text-align': 'center'},
            width: 100,
            filterable: false,
            sortable: false,
            pipes: [{
              pipe: this.roundNumericPipe,
              args: [0]
            }]
          })
        ]
      }),
      new ApDynGridPropertyColumnConfig({
        title: `Base_NSensor_NFieldView__Average`,
        field: 'Average',
        cssClass: 'right',
        headerStyle: {'text-align': 'center'},
        width: 100,
        filterable: false,
        sortable: false,
        pipes: [{
          pipe: this.roundNumericPipe,
          args: [0]
        }]
      })
    ];
  }
}
