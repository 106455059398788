import {IAction, IApInvokerArguments} from '../../interfaces';

enum MonitoringDetailActions {
  MONITORING_DETAIL_LOAD = '[monitoring-detail] load',
  MONITORING_DETAIL_LOAD_SUCCESS = '[monitoring-detail] load success',
  MONITORING_DETAIL_LOAD_FAIL = '[monitoring-detail] load fail',
  MONITORING_DETAIL_CREATE = '[monitoring-detail] create',
  MONITORING_DETAIL_EDIT = '[monitoring-detail] edit',
  MONITORING_DETAIL_DELETE = '[monitoring-detail] delete',
}

export class MonitoringDetailLoad implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_LOAD;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringDetailLoadSuccess implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_LOAD_SUCCESS;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringDetailLoadFail implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_LOAD_FAIL;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringDetailCreate implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_CREATE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringDetailEdit implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_EDIT;

  constructor(public payload: IApInvokerArguments[]) {
  }
}

export class MonitoringDetailDelete implements IAction {
  readonly type = MonitoringDetailActions.MONITORING_DETAIL_DELETE;

  constructor(public payload: IApInvokerArguments[]) {
  }
}
