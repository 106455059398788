import {Injectable}                                                from '@angular/core';
import {FormDataMonitoringTypeData, MonitoringDetailEntryFormData} from './monitoring-detail-entry.types';
import {Observable}                                                from 'rxjs';
import {
  ApDynformsConfigFieldset
}                                                                  from '../../../ap-dynforms/config/ap-dynforms-config-fieldset';
import {
  ApDynformsConfigComboBox
}                                                                  from '../../../ap-dynforms/config/ap-dynforms-config-combobox';
import {nameOf}                                                    from '../../../ap-core/utils/ap-name-of';
import {ApDynformsValidator}                                       from '../../../ap-dynforms/ap-dynforms-validator';
import {Validators}                                                from '@angular/forms';
import {
  ApDynformsConfigDatepicker
}                                                                  from '../../../ap-dynforms/config/ap-dynforms-config-datepicker';
import {
  ApDynformsConfigNumerictextbox
}                                                                  from '../../../ap-dynforms/config/ap-dynforms-config-numerictextbox';
import {
  ApDynformsConfigPlaceholder
}                                                                  from '../../../ap-dynforms/config/ap-dynforms-config-placeholder';
import {distinctUntilChanged, map}                                 from 'rxjs/operators';
import {MonitoringTypeStore}                                       from '../../../stores/fields/monitoring-type.store';
import {UnitService}                                               from '../../../services/data/unit.service';
import {
  ApDynformsConfigTextarea
}                                                                  from '../../../ap-dynforms/config/ap-dynforms-config-textarea';
import IMonitoringType = Data.Fields.IMonitoringType;
import ICampaignYear = Data.Authentication.ICampaignYear;
import IMonitoringDetail = Data.Fields.IMonitoringDetail;

@Injectable()
export class MonitoringDetailEntryConfig {

  constructor(private monitoringTypeStore: MonitoringTypeStore,
              private unitService: UnitService) {
  }


  /**
   * Generates a complete form configuration with field sets for monitoring detail entry.
   * @param formData Initial form data values.
   * @param formData$ Observable stream of form data changes.
   * @returns Array of fieldset configurations.
   * @public
   */
  public generateFormConfig(formData: MonitoringDetailEntryFormData, formData$: Observable<any>): ApDynformsConfigFieldset[] {
    return [
      new ApDynformsConfigFieldset({
        key: 'Global__General_Information',
        legend: 'Global__General_Information',
        config: [
          new ApDynformsConfigDatepicker({
            key: nameOf<MonitoringDetailEntryFormData>('Date'),
            label: 'Nutrients__FertilizationDate',
            minDate: formData.MinDateRange,
            maxDate: formData.MaxDateRange,
            infoText: '',
            value: formData.Date,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigNumerictextbox({
            key: nameOf<MonitoringDetailEntryFormData>('EcValue'),
            label: 'Global__ECStage',
            min: 0,
            max: 99,
            decimals: 0,
            format: 'n0',
            step: 1,
            value: formData?.EcValue,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          })
        ]
      }),
      new ApDynformsConfigFieldset({
        key: 'Worktype_Category_Valuation',
        legend: 'Worktype_Category_Valuation',
        config: [
          new ApDynformsConfigComboBox({
            key: nameOf<MonitoringDetailEntryFormData>('MonitoringTypeId'),
            label: 'Global_Type',
            valueField: nameOf<IMonitoringType>('Id'),
            textField: nameOf<IMonitoringType>('TranslationKey'),
            translate: true,
            value: formData?.MonitoringTypeId,
            options: this.monitoringTypeStore.MonitoringTypes$,
            valuePrimitive: true,
            validators: [
              new ApDynformsValidator({
                validator: Validators.required,
                errorKey: 'Settings__Msg_Vali_Value_Required'
              })
            ]
          }),
          new ApDynformsConfigPlaceholder(),
          new ApDynformsConfigNumerictextbox({
            key: nameOf<MonitoringDetailEntryFormData>('Value'),
            label: 'NMonitoring__MeasuredValue',
            label$: this._formDataMonitoringTypeObservable(formData$, (data) => {
              return this.unitService.getUnitWithLabelKey(data.MonitoringType?.ValueUnit, 'NMonitoring__MeasuredValue', false, false);
            }),
            min: -9999,
            max: 9999,
            decimals: 2,
            format: 'n2',
            maximumFractionDigits: 2,
            value: formData?.Value,
            value$: this._formDataMonitoringTypeObservable(formData$, (data) => {
              return data.MonitoringType?.Value ? data.FormValues.Value : undefined;
            }),
            disabled: formData?.IsValueDisabled ?? true,
            disabled$: this._formDataMonitoringTypeObservable(formData$, (data) => {
              return data.MonitoringType ? !data.MonitoringType.Value : true;
            }),
            validators: [
              new ApDynformsValidator({
                errorKey: 'Settings__Msg_Vali_Value_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigNumerictextbox({
            key: nameOf<MonitoringDetailEntryFormData>('Recommendation'),
            label: 'Nutrients__Recommendation',
            label$: this._formDataMonitoringTypeObservable(formData$, (data) => {
              return this.unitService.getUnitWithLabelKey(data.MonitoringType?.RecommendationUnit, 'Nutrients__Recommendation', false, false);
            }),
            min: -9999,
            max: 9999,
            decimals: 2,
            format: 'n2',
            maximumFractionDigits: 2,
            value: formData?.Recommendation,
            value$: this._formDataMonitoringTypeObservable(formData$, (data) => {
              return data.MonitoringType?.Recommendation ? data.FormValues.Recommendation : undefined;
            }),
            disabled: formData?.IsRecommendationDisabled ?? true,
            disabled$: this._formDataMonitoringTypeObservable(formData$, (data) => {
              return data.MonitoringType ? !data.MonitoringType.Recommendation : true;
            }),
            validators: [
              new ApDynformsValidator({
                errorKey: 'Settings__Msg_Vali_Value_Required',
                validator: Validators.required
              })
            ]
          }),
          new ApDynformsConfigTextarea({
            key: 'Notes',
            label: 'Global__Note',
            value: formData?.Notes,
            rows: 4
          })
        ]
      })
    ];
  }

  /**
   * Creates form data object from campaign year and monitoring detail.
   * @param campaignYear Current campaign year for date range.
   * @param monitoringDetail Optional existing monitoring detail.
   * @param monitoringTypes Collection of monitoring types.
   * @returns Form data object with initial values and field states.
   * @public
   */
  public getFormData(campaignYear: ICampaignYear, monitoringDetail: IMonitoringDetail | undefined, monitoringTypes: IMonitoringType[]): MonitoringDetailEntryFormData {
    const monitoringType = monitoringTypes.find(x => x.Id === monitoringDetail?.TypeId);
    return {
      MonitoringTypeId: monitoringDetail?.TypeId,
      Date: monitoringDetail?.Date ? new Date(monitoringDetail?.Date) : new Date(),
      MinDateRange: new Date(campaignYear.DefaultStart),
      MaxDateRange: new Date(campaignYear.DefaultEnd),
      EcValue: monitoringDetail?.EcStage,
      Value: monitoringDetail?.Value,
      IsValueDisabled: monitoringType ? !monitoringType.Value : true,
      Recommendation: monitoringDetail?.Recommendation,
      IsRecommendationDisabled: monitoringType ? !monitoringType.Recommendation : true,
      Notes: monitoringDetail?.Notes
    };
  }

  /**
   * Creates an observable that processes form data changes related to monitoring type.
   * @param formData$ Source form data observable.
   * @param callback Function to transform monitoring type data.
   * @returns Observable of processed form data based on monitoring type changes.
   * @public
   */
  private _formDataMonitoringTypeObservable(formData$: Observable<any>, callback: (data: FormDataMonitoringTypeData) => any): Observable<any> {
    return formData$.pipe(
      distinctUntilChanged((previous, current) => previous.MonitoringTypeId === current.MonitoringTypeId),
      map(formValues => ({
        FormValues: formValues,
        MonitoringType: this.monitoringTypeStore.MonitoringTypes.find(y => y.Id === formValues.MonitoringTypeId)
      } as FormDataMonitoringTypeData)),
      map(x => callback(x))
    );
  }
}
