import {Injectable}          from '@angular/core';
import {
  NetTypes,
  SoilSampleFieldDistributionLoad,
  SoilSampleFieldDistributionLoadSuccess,
  SoilSampleFieldLoad,
  SoilSampleFieldLoadSuccess,
  SoilSampleLoad,
  SoilSampleLoadSuccess
}                            from 'invoker-transport';
import {IStateStore}         from '../../ap-interface';
import {IStreamWatch, Store} from '../index';
import {SafeBehaviorSubject} from 'ts-tooling';
import {MapStore}            from '../map/map.store';
import {
  SamplePointsExcelExport,
  SamplePointsExcelExportSuccess, SampleTracksInfo,
  SampleTracksShapeExport,
  SampleTracksShapeExportSuccess
} from '../../../../projects/invoker-transport/src/lib/actions/evaluation';
import * as download         from 'downloadjs';
import ISoilSampleField = Data.Nutrients.ISoilSampleField;
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';


interface ISoilSampleFieldStore extends IStateStore<ISoilSampleField> {
  selectedSampleFields: ISoilSampleField[];
  exportedShapeFile: ArrayBuffer;
  exportedExcelFile: ArrayBuffer;
}

// ToDo ApMapStateService ==> mapStore

@Injectable({providedIn: 'root'})
export class SoilSampleFieldStore extends Store<ISoilSampleFieldStore> {
  constructor(private backend: ApSignalrService,
              private mapStore: MapStore) {
    super(backend, {
      loading: false,
      loaded: false,
      data: [],
      selectedSampleFields: [],
      exportedShapeFile: null,
      exportedExcelFile: null
    });

    backend.registerObservable(SoilSampleFieldLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      this.mapStore.Layers.SampleFieldLayer.replaceSource(d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SoilSampleFieldDistributionLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SoilSampleLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SampleTracksShapeExportSuccess).subscribe(d => {
      super.Mutate(s => s.exportedShapeFile, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(SamplePointsExcelExportSuccess).subscribe(d => {
      super.Mutate(s => s.exportedExcelFile, () => d.Data);
      super.SetLoadFinishState();
      if (d.Data && d.Data[0] && d.Data[1]) {
        download(d.Data[1], `${d.Data[0]}.xlsx`, 'application/excel');
      }
    });
  }

  public get SoilSampleFields$(): SafeBehaviorSubject<ISoilSampleField[]> {
    return this.Listen(s => s.data);
  }

  public get SoilSampleField(): ISoilSampleField[] {
    return this.SoilSampleFields$.getValue();
  }

  public get SelectedSoilSampleFields$(): SafeBehaviorSubject<ISoilSampleField[]> {
    return this.Listen(s => s.selectedSampleFields);
  }

  public get Loaded$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.loaded);
  }

  public get Loaded(): boolean {
    return this.Loaded$.getValue();
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return this.Listen(s => s.loading);
  }

  public get Loading(): boolean {
    return this.Loading$.getValue();
  }

  /**
   * It is necessary to reset downloads after they have been downloaded by user.
   * Otherwise, they remain in the store and once the component opens again the last/old
   * download is triggered again.
   */
  public resetDownloads(): void {
    super.Mutate(s => s.exportedShapeFile, () => null);
    super.Mutate(s => s.exportedExcelFile, () => null);
  }

  /**
   * load SampleFields they have BU-Raster into Map
   */
  public loadSoilSampleFields(): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    super.Mutate(s => s.exportedShapeFile, () => null);
    super.Mutate(s => s.exportedExcelFile, () => null);
    this.DispatchBackend(new SoilSampleFieldLoad([]));
  }

  /**
   * load different SampleField Distributions of one Nutrient
   */
  public loadMultipleSampleFieldDistributions(sampleFieldIds: number[], nutrient: number): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new SoilSampleFieldDistributionLoad([
      {Name: 'sampleFieldIds', Type: NetTypes.INT + '[]', Value: sampleFieldIds},
      {Name: 'bandNumber', Type: NetTypes.INT, Value: nutrient}
    ]));
  }

  /**
   * load the Sample Points of a SampleField also used to display the Tracks!
   */
  public loadSoilSamples(sampleFieldId: number): void {
    this.loadMultipleSoilSamples([sampleFieldId]);
  }

  /**
   * load the Sample Points of different SampleFields
   */
  public loadMultipleSoilSamples(sampleFieldIds: number[]): void {
    super.SetLoadState();
    super.Mutate(s => s.data, () => []);
    this.DispatchBackend(new SoilSampleLoad([
      {Name: 'sampleFieldIds', Type: NetTypes.INT + '[]', Value: sampleFieldIds},
    ]));
  }

  public changeSelectedSampleField(sampleFieldIds: number[]): void {
    const sampleFields = [];
    for (const sampleFieldId of sampleFieldIds) {
      const sampleField = this.SoilSampleField.Find(sf => sf?.Id === sampleFieldId);
      if (!sampleFields || sampleFields.Any(sf => sf.Id === sampleFieldId)) {
        continue;
      }
      sampleFields.push(sampleField);
    }
    this.Mutate(s => s.selectedSampleFields, () => sampleFields);
  }

  public sampleTracksShapeExport(sampleFieldIds: number[], dateExpr: string, customerExpr: string): void {
    super.SetLoadState();
    super.Mutate(s => s.exportedShapeFile, () => null);
    this.DispatchBackend(new SampleTracksShapeExport([
      {Name: 'sampleFieldIds', Type: NetTypes.INT + '[]', Value: sampleFieldIds},
      {Name: 'dateExpr', Type: NetTypes.STRING, Value: dateExpr},
      {Name: 'customerExpr', Type: NetTypes.STRING, Value: customerExpr},
    ]));
  }

  public samplePointsExcelExport(sampleFieldIds: number[], filename: string): void {
    super.SetLoadState();
    super.Mutate(s => s.exportedExcelFile, () => null);
    this.DispatchBackend(new SamplePointsExcelExport([
      {Name: 'sampleFieldIds', Type: NetTypes.INT + '[]', Value: sampleFieldIds},
      {Name: 'filename', Type: NetTypes.STRING, Value: filename},
    ]));
  }

  public sampleTracksInfo(sampleFieldIds: number[]): IStreamWatch {
    super.SetLoadState();
    super.Mutate(s => s.exportedExcelFile, () => null);
    return this.DispatchBackend(new SampleTracksInfo([
      {Name: 'sampleFieldIds', Type: NetTypes.INT + '[]', Value: sampleFieldIds}
    ]));
  }
}
