/**
 * This function is a safe way to define grid/form properties
 * which are part of an object. Instead of refering to the property as
 * a string only, this function includes a compile time check for valid property name.
 *
 * nameOf<YourObject>('yourProperty') // no compileError
 * nameOf<YourObject>('invalidProperty') // compileError
 *
 * @param name of the property
 */
export const nameOf = <T>(name: keyof T) => name;
