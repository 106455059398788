import {IStateStore}         from '../../ap-interface';
import {Injectable}          from '@angular/core';
import {Store}               from '../index';
import {ApSignalrService}    from '../../ap-core/services/ap-signalr.service';
import {
  ApCustomTypes,
  MonitoringFieldCreate,
  MonitoringFieldDelete,
  MonitoringFieldLoad,
  MonitoringFieldLoadFail,
  MonitoringFieldLoadSuccess,
  NetTypes
}                            from 'invoker-transport';
import {SafeBehaviorSubject} from 'ts-tooling';
import IMonitoringField = Data.Fields.IMonitoringField;
import IGuid = System.IGuid;
import IMonitoringDetail = Data.Fields.IMonitoringDetail;

interface IMonitoringFieldStore extends IStateStore<IMonitoringField> {
}

@Injectable({providedIn: 'root'})
export class MonitoringFieldStore extends Store<IMonitoringFieldStore> {
  constructor(public backend: ApSignalrService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: []
    });
    backend.registerObservable(MonitoringFieldLoad).subscribe(_ => {
      super.SetLoadState();
    });
    backend.registerObservable(MonitoringFieldLoadSuccess).subscribe(d => {
      super.Mutate(s => s.data, () => d.Data);
      super.SetLoadFinishState();
    });
    backend.registerObservable(MonitoringFieldLoadFail).subscribe(_ => {
      super.SetLoadFailState();
    });
    this.AfterDatabaseUpdate.subscribe(() => this.ReloadSource());
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public get MonitoringFields$(): SafeBehaviorSubject<IMonitoringField[]> {
    return this.Listen((s) => s.data);
  }

  public loadMonitoringFields(): void {
    this.DispatchBackend(new MonitoringFieldLoad([]));
  }

  public saveMonitoringFieldWithDetail(fieldId: IGuid, monitoringDetail: IMonitoringDetail): void {
    this.DispatchBackend(new MonitoringFieldCreate([
      {Name: 'fieldId', Type: NetTypes.GUID, Value: fieldId},
      {Name: 'monitoringDetail', Type: ApCustomTypes.Data_Fields_MonitoringDetail, Value: monitoringDetail}
    ]));
  }

  public deleteMonitoringField(monitoringFieldId: IGuid): void {
    this.DispatchBackend(new MonitoringFieldDelete([
      {Name: 'monitoringFieldId', Type: NetTypes.GUID, Value: monitoringFieldId}
    ]));
  }
}
