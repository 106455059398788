import {Store}                                         from '../index';
import {IStateStore}                                   from '../../ap-interface';
import {SoilSampleDatesGet, SoilSampleDatesGetSuccess} from 'invoker-transport';
import {ApDateService}                                 from '../../ap-core/services/ap-date-service';
import {Injectable}                                    from '@angular/core';
import {SafeBehaviorSubject}                           from 'ts-tooling';
import {ApSignalrService}                              from '../../ap-core/services/ap-signalr.service';

export type SampleDateObject = {
  Date: Date | undefined;
  Region: string | undefined;
};

export type SoilSampleDates = {
  [key: string]: SampleDateObject;
};

interface ISoilSampleFieldStore extends IStateStore<any> {
  dates: SoilSampleDates;
}

@Injectable({providedIn: 'root'})
export class SoilSampleDateStore extends Store<ISoilSampleFieldStore> {
  constructor(public backend: ApSignalrService,
              private dateService: ApDateService) {
    super(backend, {
      loaded: false,
      loading: false,
      data: [],
      dates: {}
    });

    backend.registerObservable(SoilSampleDatesGetSuccess).subscribe((d) => {
      super.Mutate(s => s.dates, () => {
        const o = {};
        d.Data.forEach((v: any) => {
          o[v[0]] = {
            Date: !!v[1] ? this.dateService.toFarmDateFromUtcGetJsDate(v[1]) : undefined,
            Region: v[2],
          };
        });
        return o;
      });
      this.SetLoadFinishState();
    });
  }

  public get Dates$(): SafeBehaviorSubject<SoilSampleDates> {
    return super.Listen(s => s.dates);
  }

  public get Loading$(): SafeBehaviorSubject<boolean> {
    return super.Listen(s => s.loading);
  }

  public getFieldSampleDates(): void {
    this.SetLoadState();
    this.DispatchBackend(new SoilSampleDatesGet([]));
  }
}
