import {SoilSampleDates} from '../../stores/nutrients/soilsampledate.store';
import {Injectable}      from '@angular/core';
import IField = Data.FieldManagement.IField;

@Injectable({providedIn: 'root'})
export class SoilSampleDateService {
  public getFieldsWithValidSoilSampleDate(fields: IField[], soilSampleDates: SoilSampleDates): IField[] {
    if (!soilSampleDates || !fields || fields.length === 0) {
      return [];
    }
    return fields.filter(x => {
      const sampleDateObject = soilSampleDates[x.Id as string];
      return !!sampleDateObject;
    });
  }
}
